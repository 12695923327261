/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SessionSummary { 
    /**
     * The reason the session was stopped
     */
    _stopReason?: string;
    /**
     * The code of the stop reason
     */
    _stopReasonCode?: SessionSummary.StopReasonCodeEnum;
}
export namespace SessionSummary {
    export type StopReasonCodeEnum = 'COMPLETION' | 'GENERAL_DEVICE_ERROR' | 'TIMEOUT';
    export const StopReasonCodeEnum = {
        Completion: 'COMPLETION' as StopReasonCodeEnum,
        GeneralDeviceError: 'GENERAL_DEVICE_ERROR' as StopReasonCodeEnum,
        Timeout: 'TIMEOUT' as StopReasonCodeEnum
    };
}


