import { Pipe, PipeTransform } from '@angular/core'
import { MedicalSignAlertRuleDTO } from '../../../shared/model/alert-rules.model'
import { AlertSeverity } from '../../../shared/model/alert'

@Pipe({
	name: 'bloodPressureSignSeverity'
})
export class BloodPressureSignSeverityPipe implements PipeTransform {
	transform(
		value: number[],
		diastolicPressureRules: MedicalSignAlertRuleDTO | any,
		systolicPressureRules: MedicalSignAlertRuleDTO | any
	): AlertSeverity | undefined {
		if (!value || !diastolicPressureRules || !systolicPressureRules)
			return undefined
		if (
			diastolicPressureRules.minNormal <= value[0] &&
			value <= diastolicPressureRules.maxNormal[0] &&
			systolicPressureRules.minNormal <= value[1] &&
			value <= systolicPressureRules.maxNormal[1]
		) {
			return AlertSeverity.Info
		} else if (
			value[0] <= diastolicPressureRules.maxCritical ||
			value[0] >= diastolicPressureRules.minCritical ||
			value[1] <= systolicPressureRules.maxCritical ||
			value[1] >= systolicPressureRules.minCritical
		) {
			return AlertSeverity.Critical
		} else {
			return AlertSeverity.Major
		}
	}
}
