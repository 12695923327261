/**
 * BioT User Management Service
 * APIs document (version: 6.17.1)
 *
 * The version of the OpenAPI document: 6.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Order { 
    prop: string;
    order?: Order.OrderEnum;
}
export namespace Order {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
}


