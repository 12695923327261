import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  Configuration as UmsConfiguration,
  ApiModule as UmsApiModule,
} from 'biot-client-ums'
import {
  Configuration as OrganizationConfiguration,
  ApiModule as OrganizationApiModule,
} from 'biot-client-organization'
import {
  Configuration as DeviceConfiguration,
  ApiModule as DeviceApiModule,
} from 'biot-client-device'
import {Configuration as FileConfiguration, ApiModule as FileApiModule} from 'biot-client-file';
import {Configuration as GenericEntityConfiguration, ApiModule as GenericEntityApiModule} from 'biot-client-generic-entity';
import {Configuration as MeasurementConfiguration, ApiModule as MeasurementApiModule} from 'biot-client-measurement';


export function apiConfigFactoryUms() {
  return new UmsConfiguration({
    basePath: 'https://api.dev.aiomed.biot-med.com/ums'
  });
}

export function apiConfigFactoryOrganization() {
  return new OrganizationConfiguration({
    basePath: 'https://api.dev.aiomed.biot-med.com/organization'
  });
}

export function apiConfigFactoryDevice() {
  return new DeviceConfiguration({
    basePath: 'https://api.dev.aiomed.biot-med.com/device'
  });
}

export function apiConfigFactoryFile() {
  return new FileConfiguration({
    basePath: 'https://api.dev.aiomed.biot-med.com/file'
  });
}

export function apiConfigFactoryGenericEntity() {
  return new GenericEntityConfiguration({
    basePath: 'https://api.dev.aiomed.biot-med.com/generic-entity'
  });
}

export function apiConfigFactoryMeasurementEntity() {
  return new MeasurementConfiguration({
    basePath: 'https://api.dev.aiomed.biot-med.com/measurement'
  });
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UmsApiModule.forRoot(apiConfigFactoryUms),
    OrganizationApiModule.forRoot(apiConfigFactoryOrganization),
    DeviceApiModule.forRoot(apiConfigFactoryDevice),
    FileApiModule.forRoot(apiConfigFactoryFile),
    GenericEntityApiModule.forRoot(apiConfigFactoryGenericEntity),
    MeasurementApiModule.forRoot(apiConfigFactoryMeasurementEntity)
  ],
  exports: [UmsApiModule, OrganizationApiModule, DeviceApiModule, FileApiModule, GenericEntityApiModule, MeasurementApiModule]
})
export class BiotModule {
}
