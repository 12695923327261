import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { StateRepository } from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, tap } from 'rxjs'
import {
	BackendDepartmentDTO,
	DepartmentDTO
} from '../../shared/model/permission.model'
import {
	GenericEntityAPIService,
	SearchResponseGenericEntityResponse
} from 'biot-client-generic-entity'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import { PreferenceState } from '../preference/preference.state'

export const departmentFeatureName = 'department'

@StateRepository()
@State<CollatableEntityCollections<DepartmentDTO>>({
	name: departmentFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class DepartmentState extends CollatableEntityCollectionsRepository<
	DepartmentDTO,
	EntityCollation
> {
	constructor(private genericEntityAPIService: GenericEntityAPIService) {
		super()
	}

	@Selector()
	public static departments(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO[] {
		let departments = Object.values(state.entities)
		departments = [{ id: 'all', name: 'All' }, ...departments]
		return departments
	}

	@Selector([PreferenceState.preferenceDepartmentId])
	public static department(
		state: CollatableEntityCollections<DepartmentDTO>,
		preferenceDepartmentId: string | null
	): DepartmentDTO | undefined {
		return preferenceDepartmentId
			? Object.values(state.entities).find(
					(department) => department.id === preferenceDepartmentId
			  )
			: undefined
	}

	private static toDepartmentDTO(res: BackendDepartmentDTO): DepartmentDTO {
		return {
			...res,
			id: res._id,
			name: res._name
		}
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					_templateId: {
						// @ts-ignore
						in: ['102a6aff-b950-4db6-b36c-33ef39124e5d']
					}
				}
			})
			.pipe(
				tap((res: SearchResponseGenericEntityResponse) => {
					const departments = res.data.map((e) =>
						DepartmentState.toDepartmentDTO(e as BackendDepartmentDTO)
					)
					this.upsertMany(departments)
				}),
				mapToVoid()
			)
	}
}
