import { Id } from './common'

export enum AlertRuleName {
	DefaultAlertRules = 'DefaultAlertRules'
}

export interface AlertRuleDTO extends Id {
	name: string
	heartRate: MedicalSignAlertRuleDTO
	bodyTemperature: MedicalSignAlertRuleDTO
	diastolicPressure: MedicalSignAlertRuleDTO
	systolicPressure: MedicalSignAlertRuleDTO
	respirationRate: MedicalSignAlertRuleDTO
	spO2: MedicalSignAlertRuleDTO
	bloodGlucose: MedicalSignAlertRuleDTO
	exitBed: {
		monitorFrom: number
		monitorTill: number
	}
}

export interface CreateAlertRule {
	_name?: string
	_templateId?: string
	_ownerOrganization?: any
	maxCriticalHeartRate: number
	minCriticalHeartRate: number
	maxNormalHeartRate: number
	minNormalHeartRate: number
	maxCriticalBodyTemperature: number
	maxNormalBodyTemperature: number
	minCriticalBodyTemperature: number
	minNormalBodyTemperature: number
	maxCriticalDiastolicPressure: number
	minCriticalDiastolicPressure: number
	maxNormalDiastolicPressure: number
	minNormalDiastolicPressure: number
	maxCriticalRespirationRate: number
	minCriticalRespirationRate: number
	maxNormalRespirationRate: number
	minNormalRespirationRate: number
	maxCriticalSpO2: number
	minCriticalSpO2: number
	maxNormalSpO2: number
	minNormalSpO2: number
	maxCriticalSystolicPressure: number
	maxNormalSystolicPressure: number
	minCriticalSystolicPressure: number
	minNormalSystolicPressure: number
	maxCriticalBloodGlucose: number
	maxNormalBloodGlucose: number
	minCriticalBloodGlucose: number
	minNormalBloodGlucose: number
	exitBedRiskMonitorFrom: number
	exitBedRiskMonitorTill: number
}

export interface UpdateAlertRule {
	maxCriticalHeartRate: number
	minCriticalHeartRate: number
	maxNormalHeartRate: number
	minNormalHeartRate: number
	maxCriticalBodyTemperature: number
	maxNormalBodyTemperature: number
	minCriticalBodyTemperature: number
	minNormalBodyTemperature: number
	maxCriticalDiastolicPressure: number
	minCriticalDiastolicPressure: number
	maxNormalDiastolicPressure: number
	minNormalDiastolicPressure: number
	maxCriticalRespirationRate: number
	minCriticalRespirationRate: number
	maxNormalRespirationRate: number
	minNormalRespirationRate: number
	maxCriticalSpO2: number
	minCriticalSpO2: number
	maxNormalSpO2: number
	minNormalSpO2: number
	maxCriticalSystolicPressure: number
	maxNormalSystolicPressure: number
	minCriticalSystolicPressure: number
	minNormalSystolicPressure: number
	maxCriticalBloodGlucose: number
	maxNormalBloodGlucose: number
	minCriticalBloodGlucose: number
	minNormalBloodGlucose: number
	exitBedRiskMonitorFrom: number
	exitBedRiskMonitorTill: number
}

export interface MedicalSignAlertRuleDTO {
	maxCritical: number
	maxNormal: number
	minNormal: number
	minCritical: number
}
