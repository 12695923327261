import moment from 'moment/moment'

export function monitorDecimalTime(time: string): number {
	if (!time) return 0
	const [hour, minute] = moment(time).format('HH:mm').split(':')
	return parseInt(hour) + parseInt(minute) / 60
}

export function monitorTime(decimalTime: number): string {
	if (!decimalTime) return ''
	const hour = Math.floor(decimalTime)
	const minute = Math.round((decimalTime - hour) * 60)
	return moment().set('hours', hour).set('minutes', minute).toISOString()
}
