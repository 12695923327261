/**
 * BioT Organization Service
 * APIs document (version: 7.66.0)
 *
 * The version of the OpenAPI document: 7.66.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InvitationResponse { 
    /**
     * A UUID formatted string
     */
    userId?: string;
    /**
     * A URL in which the user can confirm their email
     */
    emailConfirmationLandingPage?: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    creationTime?: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    lastModifiedTime?: string;
}

