import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'roundNumber',
})
export class RoundNumberPipe implements PipeTransform {
  transform(number: number, ...args: unknown[]): number {
    if (!number) return 0
    return Math.round(number)
  }
}
