import { Injectable } from '@angular/core'
import { CanActivate, CanLoad, Router } from '@angular/router'
import { PersistenceService } from '../../shared/services/persistence.service'
import {AuthState} from "../../store/auth/auth.state";

@Injectable({
  providedIn: 'root',
})
export class NotAuthenticated implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authState: AuthState
  ) {}

  private activate() {
    if (this.authState.isAuthenticated()) {
      this.router.navigateByUrl('/')
      return false
    } else {
      return true
    }
  }

  canActivate() {
    return this.activate()
  }

  canLoad() {
    return this.activate()
  }
}
