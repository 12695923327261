<header [ngClass]="{'patient-header': type === 'patient'}" class='header'>
  <ul>
    <li
      *ngIf='type !== "alerts" && type !== "patient"'
      [ngClass]="{'have-alerts': entitiesHaveAlertLength, 'havent-alerts': !entitiesHaveAlertLength}">
      <div class='entities-length'>{{entitiesHaveAlertLength}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-alerted'></i>
        <span>{{'header.alerted' | translate }}</span>
      </div>
    </li>
    <li
      *ngIf='type === "alerts"'
      [ngClass]="{'have-alerts': entitiesHaveAlertLength, 'havent-alerts': !entitiesHaveAlertLength}">
      <div class='entities-length'>{{entitiesHaveAlertLength}}</div>
      <div>
        <i
          *ngIf='entitiesHaveAlertLength > 0; else heventAlerts'
          nz-icon
          nzType='icons:header-ongoind'></i>
        <ng-template #heventAlerts>
          <i
            nz-icon
            nzType='icons:alert-status-ongoing'></i>
        </ng-template>
        <span>{{'header.ongoing' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "patients" || type === "all-patients"'>
      <div class='entities-length'>{{followEntitiesLength}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-follow-up'></i>
        <span>{{'header.follow' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "devices"'>
      <div class='entities-length'>{{totalDisconnect}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-follow-up'></i>
        <span>{{'header.disconnected' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "alerts"'>
      <div class='entities-length'>{{entitiesMuteLength}}</div>
      <div>
        <i
          nz-icon
          nzType='icons:header-muted'></i>
        <span>{{'header.muted' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "patients" || type === "all-patients"'>
      <div class='entities-length'>{{totalCount}}</div>
      <div>
        <i nz-icon nzType='icons:header-total'></i> <span>{{'header.totalPatients' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "devices"'>
      <div class='entities-length'>{{totalCount}}</div>
      <div>
        <i nz-icon nzType='icons:header-total'></i> <span>{{'header.totalDevices' | translate}}</span>
      </div>
    </li>
    <li *ngIf='type === "alerts"'>
      <div class='entities-length'>{{totalCount}}</div>
      <div>
        <i nz-icon nzType='icons:header-alerted' style='color: #263558'></i>
        <span>{{'header.totalAlerts' | translate}}</span>
      </div>
    </li>
  </ul>
  <div class='header__search'>
    <nz-select
      (ngModelChange)="router.navigate(['/patient', $event])"
      (nzOnSearch)='searchPatient.emit($event.trim())'
      [(ngModel)]='textSearch'
      [nzPlaceHolder]='"header.search" | translate'
      [nzShowArrow]='false'
      nzServerSearch
      nzShowSearch
    >
      <nz-option *ngFor='let item of searchPatient$ | async' [nzLabel]='item.name'
                 [nzValue]='item.id'></nz-option>
    </nz-select>
  </div>
</header>
