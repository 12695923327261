/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferenceAttributeUUIDIdRequest } from './reference-attribute-uuidid-request';
import { SessionSummary } from './session-summary';


export interface CreateSessionByUsageTypeRequestV2 { 
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _startTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _endTime?: string;
    /**
     * The Sessions state
     */
    _state: CreateSessionByUsageTypeRequestV2.StateEnum;
    _configuration?: { [key: string]: object; };
    _summary?: SessionSummary;
    _patient?: ReferenceAttributeUUIDIdRequest;
}
export namespace CreateSessionByUsageTypeRequestV2 {
    export type StateEnum = 'ACTIVE' | 'DONE' | 'PAUSED' | 'ACTIVATING' | 'PAUSING' | 'RESUMING' | 'STOPPING';
    export const StateEnum = {
        Active: 'ACTIVE' as StateEnum,
        Done: 'DONE' as StateEnum,
        Paused: 'PAUSED' as StateEnum,
        Activating: 'ACTIVATING' as StateEnum,
        Pausing: 'PAUSING' as StateEnum,
        Resuming: 'RESUMING' as StateEnum,
        Stopping: 'STOPPING' as StateEnum
    };
}


