/**
 * BioT Generic Entity Server
 * APIs document (version: 1.13.0)
 *
 * The version of the OpenAPI document: 1.13.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PageMetadata { 
    totalResults?: number;
    page?: number;
    limit?: number;
}

