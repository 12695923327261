import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { Observable, tap } from 'rxjs'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import {
	UsageSessionDTO,
	UsageSessionState as UsageSessionStateEnum
} from '../../shared/model/usage-session'
import {
	SearchResponseSessionResponseV2,
	SessionResponseV2,
	UsageSessionAPIService
} from 'biot-client-device'

export const usageSessionsFeatureName = 'usageSessions'

@StateRepository()
@State<CollatableEntityCollections<UsageSessionDTO>>({
	name: usageSessionsFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class SessionState extends CollatableEntityCollectionsRepository<
	UsageSessionDTO,
	EntityCollation
> {
	constructor(private usageSessionAPIService: UsageSessionAPIService) {
		super()
	}

	@Selector()
	public static usageSession(
		state: CollatableEntityCollections<UsageSessionDTO>
	): EntityDictionary<string, UsageSessionDTO> {
		return state.entities
	}

	private static toUsageSessionDTO(s: SessionResponseV2): UsageSessionDTO {
		return {
			...s,
			id: s._id,
			device: s._device,
			patient: s._patient,
			state: s._state as UsageSessionStateEnum
		}
	}

	@DataAction()
	public loadDeviceUsageSessions(@Payload('deviceIds') deviceIds: string[]) {
		// @ts-ignore
		return this.usageSessionAPIService
			.searchSessions({
				filter: {
					_state: {
						eq: 'ACTIVE'
					},
					'_device.id': {
						in: deviceIds
					}
				}
			})
			.pipe(
				tap((r) => this.upsertAllUsageSessions(r)),
				mapToVoid()
			)
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return this.usageSessionAPIService
			.searchSessions({
				filter: {
					_state: {
						// @ts-ignore
						eq: 'ACTIVE'
					},
					...(ids
						? {
								_id: {
									in: ids
								}
						  }
						: undefined)
				}
			})
			.pipe(
				tap((r) => this.upsertAllUsageSessions(r)),
				mapToVoid()
			)
	}

	private upsertAllUsageSessions(r: SearchResponseSessionResponseV2) {
		let usageSessions = r.data.map(SessionState.toUsageSessionDTO)
		this.upsertMany(usageSessions)
	}
}
