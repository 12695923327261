/**
 * BioT User Management Service
 * APIs document (version: 6.17.1)
 *
 * The version of the OpenAPI document: 6.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateServiceUserRequest { 
    active?: boolean;
    /**
     * A UUID formatted string
     */
    roleId: string;
    type: CreateServiceUserRequest.TypeEnum;
    /**
     * A UUID formatted string
     */
    ownerOrganizationId?: string;
    name?: string;
    accessTokenExpirationInMinutes?: number;
}
export namespace CreateServiceUserRequest {
    export type TypeEnum = 'INTERNAL_SERVICE' | 'EXTERNAL_SERVICE';
    export const TypeEnum = {
        InternalService: 'INTERNAL_SERVICE' as TypeEnum,
        ExternalService: 'EXTERNAL_SERVICE' as TypeEnum
    };
}


