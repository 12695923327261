<nz-layout>
  <nz-sider *ngIf='(isAuthorized$ | async) && width > 500' [ngClass]="{'aiomed__sider-Collapsed': isCollapseMenu}"
            class='aiomed__sider'>
    <aiomed-menu (collapseMenuEmitter)='handlerCollapseMenuEmitter($event)'
                 *ngIf='isAuthorized$ | async'
                 [isCollapseMenu]='isCollapseMenu'
                 [ngClass]="{'menu-collapsed': isCollapseMenu}" class='menu'
    ></aiomed-menu>
  </nz-sider>
  <div *ngIf='(isAuthorized$ | async) && width < 500'
       style='position: absolute; left: -9999px; visibility: hidden; opacity: 0; '>
    <aiomed-menu (collapseMenuEmitter)='handlerCollapseMenuEmitter($event)'
                 *ngIf='isAuthorized$ | async'
                 [isCollapseMenu]='isCollapseMenu'
                 [ngClass]="{'menu-collapsed': isCollapseMenu}" class='menu'
    ></aiomed-menu>
  </div>
  <nz-layout>
    <nz-content>
      <main>
        <router-outlet></router-outlet>
      </main>
    </nz-content>
  </nz-layout>
</nz-layout>
