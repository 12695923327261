/**
 * BioT File Service Server
 * APIs document description for file service
 *
 * The version of the OpenAPI document: 1.4.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FileMetadataResponse { 
    name: string;
    mimeType: string;
    id: string;
    fileAttributeName?: string;
    attached?: boolean;
    entityId?: string;
    size?: number;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    creationTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    lastModifiedTime: string;
}

