/**
 * BioT Measurement Service
 * APIs document (version: 1.34.5)
 *
 * The version of the OpenAPI document: 1.34.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SchemaAttribute { 
    creationTime: string;
    lastModifiedTime: string;
    attributeName: string;
    type: SchemaAttribute.TypeEnum;
    subType?: SchemaAttribute.SubTypeEnum;
}
export namespace SchemaAttribute {
    export type TypeEnum = 'LABEL' | 'PARAGRAPH' | 'INTEGER' | 'DECIMAL' | 'BOOLEAN' | 'SINGLE_SELECT' | 'MULTI_SELECT' | 'ADDRESS' | 'NAME' | 'PHONE' | 'EMAIL' | 'DATE_TIME' | 'DATE' | 'REFERENCE' | 'TIMEZONE' | 'LOCALE' | 'FILE' | 'IMAGE' | 'LINK' | 'WAVEFORM';
    export const TypeEnum = {
        Label: 'LABEL' as TypeEnum,
        Paragraph: 'PARAGRAPH' as TypeEnum,
        Integer: 'INTEGER' as TypeEnum,
        Decimal: 'DECIMAL' as TypeEnum,
        Boolean: 'BOOLEAN' as TypeEnum,
        SingleSelect: 'SINGLE_SELECT' as TypeEnum,
        MultiSelect: 'MULTI_SELECT' as TypeEnum,
        Address: 'ADDRESS' as TypeEnum,
        Name: 'NAME' as TypeEnum,
        Phone: 'PHONE' as TypeEnum,
        Email: 'EMAIL' as TypeEnum,
        DateTime: 'DATE_TIME' as TypeEnum,
        Date: 'DATE' as TypeEnum,
        Reference: 'REFERENCE' as TypeEnum,
        Timezone: 'TIMEZONE' as TypeEnum,
        Locale: 'LOCALE' as TypeEnum,
        File: 'FILE' as TypeEnum,
        Image: 'IMAGE' as TypeEnum,
        Link: 'LINK' as TypeEnum,
        Waveform: 'WAVEFORM' as TypeEnum
    };
    export type SubTypeEnum = 'INTEGER' | 'DECIMAL';
    export const SubTypeEnum = {
        Integer: 'INTEGER' as SubTypeEnum,
        Decimal: 'DECIMAL' as SubTypeEnum
    };
}


