export interface RouterItemData {
	title: string
	searchItem: string
	type: string
}

export const PAGE_SIZE = 10

export interface RouterData {
	devices: RouterItemData
	patients: RouterItemData
	patient: RouterItemData
	alerts: RouterItemData
	all_patients: RouterItemData
}

export const ROUTE_DATA: RouterData = {
	devices: {
		title: 'Devices',
		searchItem: 'device',
		type: 'device'
	},
	patients: {
		title: 'Home',
		searchItem: 'patient',
		type: 'home'
	},
	all_patients: {
		title: 'All Patient',
		searchItem: 'patient',
		type: 'all-patients'
	},
	patient: {
		title: 'Patient',
		searchItem: 'patient',
		type: 'patient'
	},
	alerts: {
		title: 'Alerts',
		searchItem: 'alert',
		type: 'alert'
	}
}

export enum AlertSeverity {
	Critical = 'critical',
	Major = 'major',
	Minor = 'minor',
	Notice = 'notice'
}

export enum ALERT_TYPE {
	device = 'device',
	patient = 'patient'
}
