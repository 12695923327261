/**
 * BioT User Management Service
 * APIs document (version: 6.17.1)
 *
 * The version of the OpenAPI document: 6.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateUserRequest { 
    /**
     * user\'s username. If the credential type is OTP, should be a valid phone (An E.164 formatted phone number e.g +12345678901)
     */
    username: string;
    id?: string;
    password: string;
    resetPasswordOnNextLogin?: boolean;
    credentialType?: CreateUserRequest.CredentialTypeEnum;
    idPs?: Set<CreateUserRequest.IdPsEnum>;
    groupIds?: Set<string>;
    active?: boolean;
}
export namespace CreateUserRequest {
    export type CredentialTypeEnum = 'PASSWORD' | 'OTP';
    export const CredentialTypeEnum = {
        Password: 'PASSWORD' as CredentialTypeEnum,
        Otp: 'OTP' as CredentialTypeEnum
    };
    export type IdPsEnum = 'UMS' | 'ADFS';
    export const IdPsEnum = {
        Ums: 'UMS' as IdPsEnum,
        Adfs: 'ADFS' as IdPsEnum
    };
}


