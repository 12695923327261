/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InterceptionResponse { 
    type: InterceptionResponse.TypeEnum;
    /**
     * Should only be provided if type=PRE_REQUEST or type=POST_REQUEST
     */
    apiId: string;
    /**
     * Should only be provided if type=ADAPT_ENTITY
     */
    entityName: string;
    endpoint: string;
    /**
     * A UUID formatted string
     */
    id: string;
    executionOrder: number;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    creationTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    lastModifiedTime: string;
}
export namespace InterceptionResponse {
    export type TypeEnum = 'PRE_REQUEST' | 'POST_REQUEST' | 'ADAPT_ENTITY';
    export const TypeEnum = {
        PreRequest: 'PRE_REQUEST' as TypeEnum,
        PostRequest: 'POST_REQUEST' as TypeEnum,
        AdaptEntity: 'ADAPT_ENTITY' as TypeEnum
    };
}


