import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { SignsIllnessState } from './signsIllness.state'
import { SignsIllnessService } from './signsIllness.service'

@NgModule({
	declarations: [],
	imports: [NgxsModule.forFeature([SignsIllnessState])],
	providers: [SignsIllnessService]
})
export class SignsIllnessStoreModule {}
