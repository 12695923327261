import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UserInterface } from '../../../../../shared/model/user.model'
import { AlertDTO } from '../../../../../shared/model/alert'
import { DismissAlertModel } from '../../../../../shared/model/dismissAlert.model'

@Component({
	selector: 'aiomed-new-alert',
	templateUrl: './new-alert.component.html',
	styleUrls: ['./new-alert.component.scss']
})
export class NewAlertComponent {
	@Input() currentUser: UserInterface | null
	@Input() currentAlert: AlertDTO
	@Input() dismissAlertVisible: boolean = false
	@Input() dismissAlert: EventEmitter<DismissAlertModel>
	@Output('dismissAlertVisibleEmitter') dismissAlertVisibleEmitter =
		new EventEmitter()
	resolution = 'false'
	alertInputValue: string
	alertValidateStatus: string = 'success'

	submitForm(): void {
		if ('12345' !== this.alertInputValue) {
			this.alertValidateStatus = 'error'
			return
		}
		this.alertValidateStatus = 'success'
		this.dismissAlert.emit({
			alertId: this.currentAlert.id,
			resolution: this.resolution,
			time: ''
		})
		this.dismissAlertVisibleEmitter.emit(false)
		this.alertInputValue = ''
	}

	handlerAlertInputChange({ srcElement }: any): void {
		this.alertInputValue = srcElement.value
	}

	handlerClickCancelButton() {
		this.dismissAlertVisibleEmitter.emit(false)
		this.alertInputValue = ''
	}
}
