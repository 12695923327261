/**
 * BioT Organization Service
 * APIs document (version: 7.66.0)
 *
 * The version of the OpenAPI document: 7.66.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { BasicReferencedEntityDataUUIDId } from './basic-referenced-entity-data-uuidid';
import { BasicTemplateData } from './basic-template-data';
import { ReferencedSideAttributeInformation } from './referenced-side-attribute-information';
import { Name } from './name';


export interface CaregiverResponse { 
    _name: Name;
    /**
     * A description, can be very long
     */
    _description?: string;
    /**
     * An email address in the RFC-5322 specification
     */
    _email: string;
    /**
     * An E.164 formatted phone number
     */
    _phone?: string;
    /**
     * A java locale supported by the java runtime environment
     */
    _locale?: string;
    /**
     * A persons gender
     */
    _gender?: CaregiverResponse.GenderEnum;
    /**
     * A date without a time-zone in the ISO-8601 calendar system
     */
    _dateOfBirth?: string;
    _address?: Address;
    /**
     * Is the user enabled
     */
    _enabled?: boolean;
    _employeeId: string;
    /**
     * An caregiver\'s name
     */
    _degree?: CaregiverResponse.DegreeEnum;
    /**
     * A UUID formatted string
     */
    _id: string;
    _ownerOrganization: BasicReferencedEntityDataUUIDId;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _creationTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _lastModifiedTime: string;
    /**
     * Has invitation been accepted
     */
    _invitationAccepted: boolean;
    _template?: BasicTemplateData;
    _referencers?: { [key: string]: ReferencedSideAttributeInformation; };
}
export namespace CaregiverResponse {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNDISCLOSED';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Undisclosed: 'UNDISCLOSED' as GenderEnum
    };
    export type DegreeEnum = 'MEDICAL_DOCTOR' | 'DOCTOR_OF_OSTEOPATHY' | 'PHYSICIAN_ASSISTANT' | 'REGISTERED_NURSE' | 'CLINICAL_NURSE_SPECIALISTS' | 'NURSE_PRACTITIONER' | 'LICENSED_PRACTICAL_NURSE' | 'MEDICAL_ASSISTANT' | 'NO_DEGREE' | 'OTHER';
    export const DegreeEnum = {
        MedicalDoctor: 'MEDICAL_DOCTOR' as DegreeEnum,
        DoctorOfOsteopathy: 'DOCTOR_OF_OSTEOPATHY' as DegreeEnum,
        PhysicianAssistant: 'PHYSICIAN_ASSISTANT' as DegreeEnum,
        RegisteredNurse: 'REGISTERED_NURSE' as DegreeEnum,
        ClinicalNurseSpecialists: 'CLINICAL_NURSE_SPECIALISTS' as DegreeEnum,
        NursePractitioner: 'NURSE_PRACTITIONER' as DegreeEnum,
        LicensedPracticalNurse: 'LICENSED_PRACTICAL_NURSE' as DegreeEnum,
        MedicalAssistant: 'MEDICAL_ASSISTANT' as DegreeEnum,
        NoDegree: 'NO_DEGREE' as DegreeEnum,
        Other: 'OTHER' as DegreeEnum
    };
}


