import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { AlertDTO, alertSeverityComparator } from '../../shared/model/alert'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import {
	concatMap,
	delay,
	forkJoin,
	Observable,
	of,
	switchMap,
	tap,
	timer
} from 'rxjs'
import { AlertState } from '../alert/alert.state'
import { Injectable } from '@angular/core'
import { BackendPatientDTO } from '../../shared/model/backend-device-model'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import {
	PatientDTO,
	PatientGender,
	PatientInterface,
	patientsSort,
	PatientSymptomFields
} from '../../shared/model/patient'
import {
	PatientAPIService,
	PatientResponse,
	SearchResponsePatientResponse
} from 'biot-client-organization'
import { FileState } from '../file/file.state'
import { FileDTO } from '../../shared/model/file'
import { MeasurementState } from '../measurement/measurement.state'
import {
	ObservationField,
	ObservationFields,
	PatientObservationDTO
} from '../../shared/model/patient-observation'
import { AlertRuleState } from '../alert-rule/alert-rule.state'
import {
	AlertRuleDTO,
	AlertRuleName
} from '../../shared/model/alert-rules.model'
import { cloneDeep, orderBy } from 'lodash-es'
import { PAGE_SIZE } from '../../core/helpers/variables'
import { entitiesFilter } from '../../core/helpers/filter'
import { DepartmentState } from '../department/department.state'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { base64ToFloat32Array, dataShaping } from '../../core/helpers/functions'
import { InsightState } from '../insight/insight.state'
import { InsightDTO } from '../../shared/model/insight.model'
import { SignsIllnessState } from '../signsIllness/signsIllness.state'
import {
	ConditionDTO,
	SignsIllnessDTO,
	SymptomDTO
} from '../../shared/model/simptom-condition.model'
import { PatientLogState } from '../patient-log/patient-log.state'
import { PatientLogDTO } from '../../shared/model/patient-log.model'

export const patientFeatureName = 'patient'

@StateRepository()
@State<CollatableEntityCollections<PatientDTO>>({
	name: patientFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class PatientState extends CollatableEntityCollectionsRepository<
	PatientDTO,
	EntityCollation
> {
	protected allPatients: PatientDTO[] = []

	constructor(
		private alertState: AlertState,
		private fileState: FileState,
		private patientAPIService: PatientAPIService,
		private measurementState: MeasurementState,
		private alertRuleState: AlertRuleState,
		private insightsState: InsightState,
		private symptomConditionState: SignsIllnessState,
		private patientLogState: PatientLogState
	) {
		super()
	}

	public get backendUpdates$(): Observable<number> {
		return timer(60000, 60000).pipe(
			switchMap((n) => this.loadEntities().pipe(concatMap((_) => of(n))))
		)
	}

	public get backendFocusOnUpdates$(): Observable<number> {
		const focusOnId = this.getState().focusOnId
		return timer(60000, 60000).pipe(
			switchMap((n) =>
				this.focusOnPatient(String(focusOnId)).pipe(concatMap((_) => of(n)))
			)
		)
	}

	// 2f99df0a-4d9e-4cbb-b65e-1fe8497f3419
	@Selector([
		AlertState.alerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog
	])
	public static patient(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[]
	): PatientInterface | null {
		if (state.focusOnId) {
			const patient = state.entities[state.focusOnId]
			return PatientState.hydrate(
				patient,
				Object.values(alerts),
				files,
				measurement,
				Object.values(alertRules),
				insights,
				signsIllness,
				patientLog
			)
		}
		return null
	}

	@Selector([
		AlertState.alerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		DepartmentState.department,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog
	])
	public static followUpPatients(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[]
	): PatientInterface[] {
		let patients = Object.values(state.entities)

		if (department) {
			patients = patients.filter(
				(patient) => patient.department.id === department.id
			)
		}
		return orderBy(
			patients.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					Object.values(alerts),
					files,
					measurement,
					Object.values(alertRules),
					insights,
					signsIllness,
					patientLog
				)
			),
			['criticalObservation', 'activeSignsIllness'],
			'desc'
		).filter(
			(patient) =>
				(!patient.alerts.length && patient.activeSignsIllness.length) ||
				(!patient.alerts.length && patient.criticalObservation.length)
		)
	}

	@Selector([
		AlertState.alerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		DepartmentState.department,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog
	])
	public static patients(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[]
	): PatientInterface[] {
		let patients = entitiesFilter(
			state.freeTextFilter,
			patientsSort(state.sort, Object.values(state.entities))
		)
		if (department) {
			patients = patients.filter(
				(patient) => patient.department.id === department.id
			)
		}
		return patients
			.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					Object.values(alerts).filter((a) => a.status === 'open'),
					files,
					measurement,
					Object.values(alertRules),
					insights,
					signsIllness,
					patientLog
				)
			)
	}

	@Selector()
	public static allPatients(
		state: CollatableEntityCollections<PatientDTO>
		// department: DepartmentDTO
	): PatientDTO[] {
		// let patients = Object.values(state.entities)
		// if (department) {
		// 	patients = patients.filter(
		// 		(patient) => patient.department.id === department.id
		// 	)
		// }
		return Object.values(state.entities)
	}

	@Selector([
		AlertState.alerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		DepartmentState.department,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog
	])
	public static patientsHaveAlerts(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[]
	): PatientInterface[] {
		let patients = Object.values(state.entities)
		if (department) {
			patients = patients.filter(
				(patient) => patient.department.id === department.id
			)
		}
		return patients
			.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					Object.values(alerts).filter((a) => a.status === 'open'),
					files,
					measurement,
					Object.values(alertRules),
					insights,
					signsIllness,
					patientLog
				)
			)
			.filter((patient: PatientInterface) => patient.alerts.length)
	}

	@Selector([
		AlertState.alerts,
		FileState.files,
		MeasurementState.measurement,
		AlertRuleState.alertRules,
		DepartmentState.department,
		InsightState.insights,
		SignsIllnessState.signsIllness,
		PatientLogState.patientLog
	])
	public static patientsHaveAlertsTotalCount(
		state: CollatableEntityCollections<PatientDTO>,
		alerts: EntityDictionary<string, AlertDTO>,
		files: EntityDictionary<string, FileDTO>,
		measurement: EntityDictionary<string, PatientObservationDTO>,
		alertRules: EntityDictionary<string, AlertRuleDTO>,
		department: DepartmentDTO | undefined,
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[]
	): number {
		let patients = Object.values(state.entities)
		if (department) {
			patients = patients.filter(
				(patient) => patient.department.id === department.id
			)
		}
		return patients
			.map((patient: PatientDTO) =>
				PatientState.hydrate(
					patient,
					Object.values(alerts),
					files,
					measurement,
					Object.values(alertRules),
					insights,
					signsIllness,
					patientLog
				)
			)
			.filter((patient: PatientInterface) => patient.alerts.length).length
	}

	@Selector([DepartmentState.department])
	public static totalCount(
		state: CollatableEntityCollections<PatientDTO>,
		department: DepartmentDTO | undefined
	): number {
		let patients = Object.values(state.entities)
		if (department) {
			patients = patients.filter(
				(patient) => patient.department.id === department.id
			)
		}
		return patients.length
	}

	@Selector([DepartmentState.department])
	public static patientPaginationCount(
		state: CollatableEntityCollections<PatientDTO>,
		department: DepartmentDTO | undefined
	): number {
		let patients = entitiesFilter(
			state.freeTextFilter,
			Object.values(state.entities)
		)
		if (department) {
			patients = patients.filter(
				(patient) => patient.department.id === department.id
			)
		}
		return patients.length
	}

	@Selector()
	public static patientSymptomFields(): Array<
		keyof typeof PatientSymptomFields
	> {
		return (
			Object.values(PatientSymptomFields) as Array<
				keyof typeof PatientSymptomFields
			>
		).map((value) => value)
	}

	@Selector()
	public static isLoading(
		state: CollatableEntityCollections<PatientDTO>
	): boolean {
		return state.isLoading
	}

	@Selector()
	public static focusOnPatientId(
		state: CollatableEntityCollections<PatientDTO>
	): string | null {
		return state.focusOnId
	}

	@Selector()
	public static searchPatient(
		state: CollatableEntityCollections<PatientDTO>
	): PatientDTO[] {
		return Object.values(state.entities).filter((p) =>
			p.name.toLowerCase().includes(!state.searchText ? '' : state.searchText)
		)
	}

	@Selector([MeasurementState.measurement])
	public static patientEcg(
		state: CollatableEntityCollections<PatientDTO>,
		measurements: EntityDictionary<string, PatientObservationDTO>
	): { value: number; timestamp: string }[] {
		if (!state.focusOnId || !measurements[state.focusOnId]) return []
		const ecg = measurements[state.focusOnId].latest
			.filter((data) => data.ecg)
			.map((data) => {
				const tmpArray: any[] = []
				let timestamp = data.timestamp
				base64ToFloat32Array(data.ecg.data)
					.reverse()
					.forEach((value: number, idx) => {
						timestamp =
							idx === 0
								? data.timestamp
								: dataShaping(timestamp, data.ecg.metadata.frequency)
						tmpArray.push({
							value: value,
							timestamp
						})
					})
				return tmpArray
			})
			.flat()
		let ecdOrderByDesc = orderBy(ecg, 'timestamp', 'desc')
		ecdOrderByDesc.splice(120)
		return ecdOrderByDesc.length >= 120
			? orderBy(ecdOrderByDesc, 'timestamp', 'asc')
			: []
	}

	private static hydrate(
		patient: PatientDTO,
		alerts: AlertDTO[],
		files: EntityDictionary<string, FileDTO>,
		observations: EntityDictionary<string, PatientObservationDTO>,
		alertRules: AlertRuleDTO[],
		insights: InsightDTO[],
		signsIllness: SignsIllnessDTO,
		patientLog: PatientLogDTO[]
	): PatientInterface {
		const defaultAlertRules = alertRules.filter(
			(alertRule: AlertRuleDTO) =>
				alertRule.name.split(' ').join('') === AlertRuleName.DefaultAlertRules
		)
		const patientAlertRules = alertRules.filter(
			(alertRule: AlertRuleDTO) =>
				patient.alertRules &&
				patient.alertRules.id === alertRule.id &&
				patient.alertRules.name.split(' ').join('') !==
					AlertRuleName.DefaultAlertRules
		)
		const patientAlerts = alerts
			.filter((a) => a.patient?.id == patient.id)
			.sort((a, b) => alertSeverityComparator(a.severity, b.severity))
		const patientInsights = insights.filter((i) => i.patientId === patient.id)
		const symptoms = signsIllness.symptoms.map((item: SymptomDTO) =>
			PatientState.toPatientSignsIllnessTransform(
				Array.isArray(patient.symptoms)
					? patient.symptoms
					: (patient.symptoms as any).split(','),
				item,
				'symptom'
			)
		)

		const conditions = signsIllness.conditions.map((item: SymptomDTO) =>
			PatientState.toPatientSignsIllnessTransform(
				Array.isArray(patient.conditions)
					? patient.conditions
					: (patient.conditions as any).split(','),
				item,
				'condition'
			)
		)

		return {
			...patient,
			alerts: patientAlerts,
			maxAlertSeverity: patientAlerts.length ? patientAlerts[0].severity : null,
			patientLog: orderBy(
				patientLog.filter((pl) => pl.logEntryPatient.id === patient.id),
				'creationTime',
				'asc'
			),
			avatar:
				patient.avatar &&
				files[patient.avatar.id] &&
				files[patient.avatar.id]?.signedUrl
					? files[patient.avatar.id]
					: null,
			symptoms: orderBy(symptoms, 'checked', 'desc'),
			conditions: orderBy(conditions, 'checked', 'desc'),
			unreadMessages: patientLog.filter(
				(pl) => pl.logEntryPatient.id === patient.id && !pl.read
			).length,
			activeSignsIllness: [
				...conditions.filter((c) => c.checked),
				...symptoms.filter((c) => c.checked)
			],
			insights: patientInsights.length ? patientInsights[0].data : [],
			criticalObservation:
				observations[patient.id] &&
				observations[patient.id].latest[
					observations[patient.id].latest.length - 1
				]
					? PatientState.toPatientCriticalObservationsTransform(
							observations[patient.id].latest[
								observations[patient.id].latest.length - 1
							],
							patientAlertRules.length
								? patientAlertRules[0]
								: defaultAlertRules.length
								? defaultAlertRules[0]
								: null
					  )
					: [],
			observations:
				observations[patient.id] &&
				observations[patient.id].latest[
					observations[patient.id].latest.length - 1
				]
					? PatientState.toPatientObservationsTransform(
							observations[patient.id].latest[
								observations[patient.id].latest.length - 1
							]
					  )
					: {},
			defaultAlertRules: defaultAlertRules.length ? defaultAlertRules[0] : null,
			patientAlertRules: patientAlertRules.length ? patientAlertRules[0] : null
		}
	}

	private static toPatientSignsIllnessTransform(
		patientSignsIllness: string[],
		data: SymptomDTO | ConditionDTO,
		type: string
	) {
		return {
			...data,
			type,
			checked: !!patientSignsIllness.find((si) => si === data.key)
		}
	}

	private static toPatientObservationsTransform(observation: ObservationField):
		| {
				[key in ObservationFields]: {
					lastUpdated: string
					value: number | string
				}
		  }
		| {} {
		const transformObservation:
			| {
					[key in ObservationFields]: {
						lastUpdated: string
						value: number | string
					}
			  }
			| any = {}
		Object.keys(observation).forEach((key) => {
			transformObservation[key] = {
				lastUpdated: observation.timestamp,
				// @ts-ignore
				value: observation[key]
			}
		})
		return transformObservation
	}

	private static toPatientCriticalObservationsTransform(
		observation: ObservationField,
		defaultAlertRules: AlertRuleDTO | null
	): any[] {
		if (!defaultAlertRules) return []
		const transformObservation: any[] = []
		Object.keys(observation).forEach((key: string) => {
			const tmp = key.split('_')
			const alertRulesKey =
				tmp.length === 2 && tmp[1].length
					? `${tmp[0]}${tmp[1][0].toUpperCase() + tmp[1].slice(1)}`
					: `${tmp[0]}`
			// @ts-ignore
			const value: string = observation[key]
			const currentAlertRule =
				// @ts-ignore
				defaultAlertRules[alertRulesKey === 'spo2' ? 'spO2' : alertRulesKey]
			if (!currentAlertRule) return
			else if (
				value <= currentAlertRule.maxCritical ||
				value >= currentAlertRule.minCritical
			) {
				transformObservation.push({
					subject: key,
					lastUpdated: observation.timestamp,
					value
				})
			}
		})
		return transformObservation
	}

	private static toPatientDTO(res: BackendPatientDTO): PatientDTO {
		return {
			...res,
			id: res._id,
			alertRules: (res as any).alertRules || null,
			firstName: res._name.firstName,
			lastName: res._name.lastName,
			name: res._name.firstName + ' ' + res._name.lastName,
			conditions: !res.conditions ? [] : res.conditions,
			gender: res._gender as PatientGender,
			caregiver: res._caregiver,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime,
			symptoms: !res.symptoms ? [] : res.symptoms,
			department: res.department,
			dateOfBirth:
				res._dateOfBirth && res._dateOfBirth.length
					? new Date(res._dateOfBirth)
					: undefined
		}
	}

	@DataAction()
	focusOnPatient(@Payload('id') id: string): Observable<void> {
		this.ctx.patchState({
			focusOnId: id,
			isLoading: true
		})
		const currentPatient = Object.values(this.getState().entities).filter(
			(patient) => patient.id === id
		)
		this.setPatientItemsSetting(currentPatient)
		this.ctx.patchState({ isLoading: false })
		return of()
	}

	@DataAction()
	public setSearchPatientName(@Payload('text') text: string): void {
		this.ctx.patchState({
			searchText: text.toLowerCase()
		})
	}

	@DataAction()
	public loadPatientImages(@Payload('ids') ids: string[]) {
		const fileIds = Object.values(this.ctx.getState().entities)
			.map((patient: BackendPatientDTO | PatientDTO) => patient.avatar?.id)
			.filter((i: string | any) => i)
		this.fileState.loadEntities(fileIds)
	}

	@DataAction()
	updatePatient(
		@Payload('id') id: string,
		@Payload('data') data: any
	): Observable<void> {
		return this.patientAPIService.updatePatient(id, data).pipe(
			tap((patient) => {
				this.upsertOne(PatientState.toPatientDTO(patient as BackendPatientDTO))
			}),
			mapToVoid()
		)
	}

	protected setPaginationSetting(type?: string): Observable<void> {
		const state = this.ctx.getState()
		const patients = cloneDeep(Object.values(state.entities))
		if (type && type === 'haveAlert') {
			let patientsHaveAlert: PatientDTO[] = []
			const ids: string[] | any = this.alertState.entitiesArray
				.sort((a, b) => alertSeverityComparator(a.severity, b.severity))
				.map((alert: AlertDTO) => alert.patient?.id)
				.filter((i) => i)
			patients.forEach((patient) => {
				const idx = ids.findIndex((id: string) => id === patient.id)
				if (idx === -1) return
				patientsHaveAlert = [...patientsHaveAlert, patient]
			})
			this.setPatientItemsSetting(
				patientsHaveAlert.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			)
		} else {
			this.setPatientItemsSetting(
				patients.slice(state.pageSize - PAGE_SIZE, state.pageSize)
			)
		}
		return of()
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		const state = this.ctx.getState()
		return this.patientAPIService
			.searchPatients({
				filter: {
					_enabled: {
						// @ts-ignore
						eq: 'ENABLED'
					}
				}
			})
			.pipe(
				delay(300),
				tap((res: SearchResponsePatientResponse) => {
					this.ctx.patchState({
						totalCount: state.freeTextFilter
							? state.totalCount
							: res.metadata.page?.totalResults!
					})
					const patients = res.data as BackendPatientDTO[]
					const focusOnId = this.getState().focusOnId
					this.upsertAllPatients(patients)
					this.setPaginationSetting()
					this.setPaginationSetting('haveAlert')
					if (focusOnId) {
						this.focusOnPatient(focusOnId)
					}
					const patientIds = patients.map(
						(patient: BackendPatientDTO | PatientDTO) =>
							'_id' in patient ? patient._id : patient.id
					)
					this.insightsState.setInsights()
					this.patientLogState.loadEntities()
					this.symptomConditionState.setSignsIllness()
					this.measurementState.loadPatientObservations(patientIds)
					this.measurementState.setMeasurementsWSSubscriptions()
				}),
				mapToVoid()
			)
	}

	private setPatientItemsSetting(patients: BackendPatientDTO[] | PatientDTO[]) {
		const fileIds = patients
			.map((patient: BackendPatientDTO | PatientDTO) => patient.avatar?.id)
			.filter((i: string | any) => i)
		this.ctx.patchState({ isLoading: false })
		return forkJoin([
			this.fileState.loadEntities(fileIds),
			this.alertRuleState.loadEntities()
		])
	}

	private upsertAllPatients(res: PatientResponse[]) {
		this.allPatients = res.map((patient: PatientResponse) =>
			PatientState.toPatientDTO(patient as BackendPatientDTO)
		)
		this.upsertMany(this.allPatients)
	}
}
