/**
 * BioT Generic Entity Server
 * APIs document (version: 1.13.0)
 *
 * The version of the OpenAPI document: 1.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import {
	HttpClient,
	HttpContext,
	HttpEvent,
	HttpHeaders,
	HttpParameterCodec,
	HttpParams,
	HttpResponse
} from '@angular/common/http'
import { CustomHttpParameterCodec } from '../encoder'
import { Observable } from 'rxjs'

// @ts-ignore
import { ErrorResponse } from '../model/error-response'
// @ts-ignore
import { VersionResponse } from '../model/version-response'

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables'
import { Configuration } from '../configuration'

@Injectable({
	providedIn: 'root'
})
export class HealthCheckAPIService {
	public defaultHeaders = new HttpHeaders()
	public configuration = new Configuration()
	public encoder: HttpParameterCodec
	protected basePath = 'http://localhost/generic-entity'

	constructor(
		protected httpClient: HttpClient,
		@Optional() @Inject(BASE_PATH) basePath: string,
		@Optional() configuration: Configuration
	) {
		if (configuration) {
			this.configuration = configuration
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath
			}
			this.configuration.basePath = basePath
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec()
	}

	/**
	 * Service Health check API
	 * Service Health check API  The API returns 200 if the service is up, used by the Load Balancer  This API is **public**.
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public healthCheck(
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<VersionResponse>

	public healthCheck(
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<VersionResponse>>

	public healthCheck(
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<VersionResponse>>

	public healthCheck(
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		let localVarHeaders = this.defaultHeaders

		let localVarHttpHeaderAcceptSelected: string | undefined =
			options && options.httpHeaderAccept
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json']
			localVarHttpHeaderAcceptSelected =
				this.configuration.selectHeaderAccept(httpHeaderAccepts)
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set(
				'Accept',
				localVarHttpHeaderAcceptSelected
			)
		}

		let localVarHttpContext: HttpContext | undefined =
			options && options.context
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext()
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json'
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text'
			} else if (
				this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
			) {
				responseType_ = 'json'
			} else {
				responseType_ = 'blob'
			}
		}

		return this.httpClient.get<VersionResponse>(
			`${this.configuration.basePath}/system/healthCheck`,
			{
				context: localVarHttpContext,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		)
	}

	// @ts-ignore
	private addToHttpParams(
		httpParams: HttpParams,
		value: any,
		key?: string
	): HttpParams {
		httpParams = this.addToHttpParamsRecursive(httpParams, value, key)
		return httpParams
	}

	private addToHttpParamsRecursive(
		httpParams: HttpParams,
		value?: any,
		key?: string
	): HttpParams {
		if (value == null) {
			return httpParams
		}

		if (typeof value === 'object') {
			// @ts-ignore
			httpParams = httpParams.append(key, JSON.stringify(value))
		} else if (key != null) {
			// @ts-ignore
			httpParams = httpParams.append(key, value)
		} else {
			throw Error('key may not be null if value is not object or array')
		}
		return httpParams
	}
}
