/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceOperationalStatusResponse } from './device-operational-status-response';
import { ConnectionStatus } from './connection-status';


export interface DeviceStatus { 
    _fwVersion?: string;
    _hwVersion?: string;
    _certificateStatus?: DeviceStatus.CertificateStatusEnum;
    /**
     * A date without a time-zone in the ISO-8601 calendar system
     */
    _bootTime?: string;
    _connection?: ConnectionStatus;
    _operational?: DeviceOperationalStatusResponse;
}
export namespace DeviceStatus {
    export type CertificateStatusEnum = 'NOT_INSTALLED' | 'BOOTSTRAP' | 'PERMANENT';
    export const CertificateStatusEnum = {
        NotInstalled: 'NOT_INSTALLED' as CertificateStatusEnum,
        Bootstrap: 'BOOTSTRAP' as CertificateStatusEnum,
        Permanent: 'PERMANENT' as CertificateStatusEnum
    };
}


