import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { PreferenceInterface } from '../../shared/model/preference.model'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment'
import { registerLocaleData } from '@angular/common'

export const preferenceFeatureName = 'preference'

@StateRepository()
@State<PreferenceInterface>({
	name: preferenceFeatureName,
	defaults: {
		departmentId: null,
		isCollapseMenu: false,
		soundNotifications: null,
		lg: null
	}
})
@Injectable()
export class PreferenceState extends NgxsDataRepository<PreferenceInterface> {
	constructor(private translate: TranslateService) {
		super()
	}

	@Selector()
	public static preferenceDepartmentId(
		state: PreferenceInterface
	): string | null {
		return state.departmentId
	}

	@Selector()
	public static preferenceIsCollapseMenu(state: PreferenceInterface): boolean {
		return state.isCollapseMenu
	}

	@Selector()
	public static preferenceSoundNotifications(
		state: PreferenceInterface
	): boolean | null {
		return state.soundNotifications
	}

	@Selector()
	public static language(state: PreferenceInterface): string | null {
		return state.lg
	}

	@DataAction()
	setPreferenceDepartmentId(@Payload('id') id: string): void {
		if (id === 'all') this.ctx.patchState({ departmentId: null })
		else this.ctx.patchState({ departmentId: id })
	}

	@DataAction()
	setPreferenceIsCollapseMenu(
		@Payload('isCollapseMenu') isCollapseMenu: boolean
	): void {
		this.ctx.patchState({ isCollapseMenu })
	}

	@DataAction()
	setPreferenceSoundNotifications(
		@Payload('soundNotifications') soundNotifications: boolean
	): void {
		this.ctx.patchState({ soundNotifications })
	}

	@DataAction()
	setLanguage(@Payload('lg') lg: string): void {
		this.translate.use(lg)
		moment.locale(lg)
		registerLocaleData(lg)
		this.ctx.patchState({ lg })
	}
}
