/**
 * BioT File Service Server
 * APIs document description for file service
 *
 * The version of the OpenAPI document: 1.4.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DownloadFileUrlResponse { 
    name: string;
    mimeType: string;
    id?: string;
    signedUrl?: string;
}

