/**
 * BioT Organization Service
 * APIs document (version: 7.66.0)
 *
 * The version of the OpenAPI document: 7.66.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BasicTemplateData { 
    id?: string;
    name?: string;
    displayName?: string;
}

