export * from './health-check-api.service';
import { HealthCheckAPIService } from './health-check-api.service';
export * from './internal-template-api.service';
import { InternalTemplateAPIService } from './internal-template-api.service';
export * from './measurement-schema-api.service';
import { MeasurementSchemaAPIService } from './measurement-schema-api.service';
export * from './measurements-api.service';
import { MeasurementsAPIService } from './measurements-api.service';
export * from './overview-api.service';
import { OverviewAPIService } from './overview-api.service';
export const APIS = [HealthCheckAPIService, InternalTemplateAPIService, MeasurementSchemaAPIService, MeasurementsAPIService, OverviewAPIService];
