import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core'
import { Observable, Subscription, timer } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer'
import { Select } from '@ngxs/store'
import { AlertState } from '../../../../../store/alert/alert.state'
import { Router } from '@angular/router'
import { PatientState } from '../../../../../store/patient/patient.state'
import { AlertDTO, AlertSeverity } from '../../../../../shared/model/alert'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import { DepartmentState } from '../../../../../store/department/department.state'
import { RootStore } from '../../../../../store/root-store'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { PreferenceState } from '../../../../../store/preference/preference.state'
import { environment } from '../../../../../environments/environment'
import { UserState } from '../../../../../store/user/user.state'
import { UserInterface } from '../../../../../shared/model/user.model'
import { AuthState } from '../../../../../store/auth/auth.state'
import { DepartmentDTO } from '../../../../../shared/model/permission.model'

@UntilDestroy()
@Component({
	selector: 'aiomed-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
	@Input() isCollapseMenu: boolean | null
	@Output('collapseMenuEmitter') collapseMenu = new EventEmitter()
	@Select(UserState.currentUser)
	user$: Observable<UserInterface | null>
	@Select(RootStore.devicesAlertsCount)
	devicesAlertsCount$: Observable<number>
	@Select(RootStore.alertsTotalCount)
	patientsAlertsCount$: Observable<number>
	@Select(AlertState.alerts)
	alerts$: Observable<EntityDictionary<string, AlertDTO>>
	@Select(PreferenceState.preferenceSoundNotifications)
	preferenceSoundNotifications$: Observable<null | boolean>
	@Select(DepartmentState.department)
	currentDepartment$: Observable<DepartmentDTO | undefined>
	@Select(DepartmentState.departments)
	departments$: Observable<DepartmentDTO[]>
	@Select(PreferenceState.language)
	language$: Observable<string>
	placement: NzDrawerPlacement = 'left'
	request: XMLHttpRequest = new XMLHttpRequest()
	context: AudioContext = new AudioContext()
	source: AudioBufferSourceNode
	timerSub: Subscription
	confirmModal?: NzModalRef
	popoverVisible: boolean = false

	constructor(
		private alertState: AlertState,
		private patientState: PatientState,
		private permissionState: DepartmentState,
		private preferenceState: PreferenceState,
		public router: Router,
		private modal: NzModalService,
		private authState: AuthState,
		private cdRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.initializeListeners()
		this.setAudioContext()
	}

	initializeListeners(): void {
		this.patientState.loadEntities()
		this.permissionState.loadEntities()
		this.alertState.backendUpdates$.pipe(untilDestroyed(this)).subscribe()
		this.preferenceSoundNotifications$.subscribe((data) => {
			if (typeof data === 'object') {
				this.setConfirmModalSetting()
				return
			}
			if (!data || !environment.production) return
			this.setMediaSourceWhenHaveCriticalAlert()
		})
	}

	setConfirmModalSetting() {
		this.confirmModal = this.modal.confirm({
			nzTitle: 'Do you want to receive sound notifications for alerts?',
			nzOnOk: () => {
				navigator.mediaDevices
					.getUserMedia({ audio: true })
					.then((stream) => {
						this.preferenceState.setPreferenceSoundNotifications(true)
					})
					.catch((error) =>
						this.preferenceState.setPreferenceSoundNotifications(false)
					)
			},
			nzOnCancel: () => {
				this.preferenceState.setPreferenceSoundNotifications(false)
			}
		})
	}

	setMediaSourceWhenHaveCriticalAlert(): void {
		this.alerts$
			.pipe(untilDestroyed(this))
			.subscribe((alerts: EntityDictionary<string, AlertDTO>) => {
				const alertsDTO: AlertDTO[] = Object.values(alerts)
				const alert: AlertDTO | undefined = alertsDTO.find(
					(alert) => alert.severity === AlertSeverity.Critical
				)
				if (!alert || !alertsDTO.length) {
					if (this.timerSub) {
						this.timerSub.unsubscribe()
						this.request.abort()
					}
					return
				}
				navigator.mediaDevices
					.getUserMedia({ audio: true })
					.then((stream) => {
						this.timerSub = timer(0, 50000)
							.pipe(untilDestroyed(this))
							.subscribe(() => {
								this.source.start(0)
								setTimeout(() => {
									this.source.stop(0)
									this.request.abort()
									this.setAudioContext()
								}, 2000)
							})
					})
					.catch((error) => console.log(error))
			})
	}

	setAudioContext() {
		this.source = this.context.createBufferSource()
		this.source.connect(this.context.destination)
		this.request.open('GET', 'assets/door_bell.aac', true)
		this.request.timeout = 10000
		this.request.responseType = 'arraybuffer'
		this.request.onload = () => {
			this.context.decodeAudioData(
				this.request.response,
				(response) => {
					this.source.buffer = response
					this.source.loop = true
				},
				function () {
					console.error('The request failed.')
				}
			)
		}
		this.request.send()
	}

	close(): void {
		this.collapseMenu.emit(false)
	}

	handlerChangeDepartment(departmentId: string) {
		this.preferenceState.setPreferenceDepartmentId(departmentId)
	}

	handlerClickLogout() {
		this.router.navigateByUrl('/login')
		this.authState.reset()
	}

	handlerChangeLanguageEmitter($event: string) {
		this.preferenceState.setLanguage($event)
	}
}
