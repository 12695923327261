import { Injectable } from '@angular/core'
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { PersistenceService } from '../../shared/services/persistence.service'
import { AuthState } from '../../store/auth/auth.state'

@Injectable({
	providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private authState: AuthState
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		const token = this.authState.accessToken();
		if (token) {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			})
		}
		return next.handle(req)
	}
}
