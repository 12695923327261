/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConnectionStatus { 
    _connected?: boolean;
    _ipAddress?: string;
    /**
     * A date without a time-zone in the ISO-8601 calendar system
     */
    _lastConnectedTime?: string;
}

