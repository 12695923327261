<section class='alert-rules'>
  <nz-modal (nzOnCancel)='handlerClickCancelButton()' [(nzVisible)]='isVisible'>
    <div *nzModalTitle class='alert-rules__header-title'>
      <p>{{'alertRule.title' | translate}}</p>
    </div>
    <div *nzModalContent>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.heart_rate' | translate}}</div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minCritical' | translate}}</span>
          <nz-slider [(ngModel)]='heartRateCritical' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxCritical' | translate}}</span>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minNormal' | translate}}</span>
          <nz-slider [(ngModel)]='heartRateNormal' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxNormal' | translate}}</span>
        </div>
      </div>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.body_temperature' | translate}}</div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minCritical' | translate}}</span>
          <nz-slider [(ngModel)]='bodyTemperatureCritical' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxCritical' | translate}}</span>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minNormal' | translate}}</span>
          <nz-slider [(ngModel)]='bodyTemperatureNormal' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxNormal' | translate}}</span>
        </div>
      </div>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.diastolicPressure' | translate}}</div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minCritical' | translate}}</span>
          <nz-slider [(ngModel)]='diastolicPressureCritical' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxCritical' | translate}}</span>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minNormal' | translate}}</span>
          <nz-slider [(ngModel)]='diastolicPressureNormal' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxNormal' | translate}}</span>
        </div>
      </div>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.respiration_rate' | translate}}</div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minCritical' | translate}}</span>
          <nz-slider [(ngModel)]='respirationRateCritical' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxCritical' | translate}}</span>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minNormal' | translate}}</span>
          <nz-slider [(ngModel)]='respirationRateNormal' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxNormal' | translate}}</span>
        </div>
      </div>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.spo2' | translate}}</div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minCritical' | translate}}</span>
          <nz-slider [(ngModel)]='spO2Critical' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxCritical' | translate}}</span>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minNormal' | translate}}</span>
          <nz-slider [(ngModel)]='spO2Normal' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxNormal' | translate}}</span>
        </div>
      </div>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.systolicPressure' | translate}}</div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minCritical' | translate}}</span>
          <nz-slider [(ngModel)]='systolicPressureCritical' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxCritical' | translate}}</span>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minNormal' | translate}}</span>
          <nz-slider [(ngModel)]='systolicPressureNormal' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxNormal' | translate}}</span>
        </div>
      </div>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.blood_glucose' | translate}}</div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minCritical' | translate}}</span>
          <nz-slider [(ngModel)]='bloodGlucoseCritical' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxCritical' | translate}}</span>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.minNormal' | translate}}</span>
          <nz-slider [(ngModel)]='bloodGlucoseNormal' [nzMax]='300' [nzMin]='0' nzRange></nz-slider>
          <span>{{'alertRule.maxNormal' | translate}}</span>
        </div>
      </div>
      <div class='alert-rules__item'>
        <div class='alert-rules__item-title'>{{'alertSubject.exit_bed_risk' | translate}}</div>
        <div class='alert-rules__item-container' style='padding-bottom: 10px'>
          <span>{{'alertRule.monitorTill' | translate}}</span>
          <nz-time-picker [(ngModel)]='monitorTillTime' [nzAllowEmpty]='false' nzFormat='HH:mm'></nz-time-picker>
        </div>
        <div class='alert-rules__item-container'>
          <span>{{'alertRule.monitorFrom' | translate}}</span>
          <nz-time-picker [(ngModel)]='monitorFromTime' [nzAllowEmpty]='false' nzFormat='HH:mm'></nz-time-picker>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <form nz-form nzLayout='vertical'>
        <button (click)='handlerClickCancelButton()' class='alert-rules__button' nz-button nzBlock nzType='primary'>
          {{'button.cancel' | translate}}
        </button>
        <button (click)='handlerClick()' class=' btn-prime alert-rules__button-btn-prime' nz-button nzBlock
                nzType='primary'
                type='submit'>{{'button.save' | translate}}
        </button>
      </form>
    </div>
  </nz-modal>
</section>
