/**
 * BioT User Management Service
 * APIs document (version: 6.17.1)
 *
 * The version of the OpenAPI document: 6.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FilterV2 { 
    _in?: Array<string>;
    eq?: object;
    lt?: number;
    lte?: number;
    gt?: number;
    gte?: number;
    like?: string;
    from?: string;
    to?: string;
    isNull?: boolean;
    isNotNull?: boolean;
    not?: object;
}

