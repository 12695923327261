/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Credentials } from './credentials';


export interface TempCredentialsForClientResponse { 
    endpoint?: string;
    credentials?: Credentials;
    types?: Set<TempCredentialsForClientResponse.TypesEnum>;
    topics?: { [key: string]: { [key: string]: Set<string>; }; };
}
export namespace TempCredentialsForClientResponse {
    export type TypesEnum = 'MEASUREMENT' | 'USAGE_SESSION_STATE_CHANGED' | 'COMMAND_STATE_CHANGED';
    export const TypesEnum = {
        Measurement: 'MEASUREMENT' as TypesEnum,
        UsageSessionStateChanged: 'USAGE_SESSION_STATE_CHANGED' as TypesEnum,
        CommandStateChanged: 'COMMAND_STATE_CHANGED' as TypesEnum
    };
}


