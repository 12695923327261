import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { StateRepository } from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { delay, EMPTY, Observable, of, timer } from 'rxjs'
import { DownloadFileUrlResponse, FileAPIService } from 'biot-client-file'
import { FileDTO } from '../../shared/model/file'

export const fileFeatureName = 'file'

@StateRepository()
@State<CollatableEntityCollections<FileDTO>>({
	name: fileFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class FileState extends CollatableEntityCollectionsRepository<
	FileDTO,
	EntityCollation
> {
	constructor(private fileAPIService: FileAPIService) {
		super()
	}

	@Selector()
	public static files(
		state: CollatableEntityCollections<FileDTO>
	): EntityDictionary<string, FileDTO> {
		return state.entities
	}

	private static toFileDTO(file: DownloadFileUrlResponse): FileDTO {
		return {
			...file,
			size: null,
			signedUrl: file.signedUrl!,
			id: file.id!
		}
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		if (!ids) return EMPTY
		const loadedIds = this.ids
		const idsToLoad = ids.filter((i: string) => !loadedIds.includes(i))
		let tmpFiles: DownloadFileUrlResponse[] = []
		for (let i = 0; i < idsToLoad.length; i++) {
			this.fileAPIService
				.getDownloadFileUrl(idsToLoad[i])
				.subscribe(
					(res: DownloadFileUrlResponse) => (tmpFiles = [...tmpFiles, res])
				)
		}
		timer(1000)
			.pipe(delay(750))
			.subscribe(() => this.upsertAllFiles(tmpFiles))
		return of()
		// return forkJoin(
		// 	idsToLoad.map((i: string) => timer(0).pithis.fileAPIService.getDownloadFileUrl(i))
		// ).pipe(
		// 	tap((res: DownloadFileUrlResponse[]) => this.upsertAllFiles(res)),
		// 	mapToVoid()
		// )
	}

	private upsertAllFiles(res: DownloadFileUrlResponse[]) {
		const usageFiles = res.map(FileState.toFileDTO)
		this.upsertMany(usageFiles)
	}
}
