import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PatientInterface } from '../../../../../shared/model/patient'
import {
	AlertRuleDTO,
	CreateAlertRule,
	UpdateAlertRule
} from '../../../../../shared/model/alert-rules.model'
import {
	monitorDecimalTime,
	monitorTime
} from '../../../../../core/helpers/monitor-time-format'

@Component({
	selector: 'aiomed-alert-rules',
	templateUrl: './alert-rules.component.html',
	styleUrls: ['./alert-rules.component.scss']
})
export class AlertRulesComponent implements OnInit {
	@Input() isVisible: boolean = false
	@Input() patient: PatientInterface
	@Input() operationsToAlertRule: EventEmitter<any>
	@Output('alertRuleModelEmitter') alertRuleModel = new EventEmitter()
	havePatientAlertRules = false
	heartRateCritical = [0, 0]
	heartRateNormal = [0, 0]
	bodyTemperatureCritical = [0, 0]
	bodyTemperatureNormal = [0, 0]
	diastolicPressureCritical = [0, 0]
	diastolicPressureNormal = [0, 0]
	respirationRateCritical = [0, 0]
	respirationRateNormal = [0, 0]
	spO2Critical = [0, 0]
	spO2Normal = [0, 0]
	systolicPressureCritical = [0, 0]
	systolicPressureNormal = [0, 0]
	bloodGlucoseCritical = [0, 0]
	bloodGlucoseNormal = [0, 0]
	monitorTillTime: string
	monitorFromTime: string

	constructor() {}

	ngOnInit(): void {
		if (this.patient.patientAlertRules) {
			this.setAlertRuleSetting(this.patient.patientAlertRules)
			this.havePatientAlertRules = true
		} else if (this.patient.defaultAlertRules) {
			this.setAlertRuleSetting(this.patient.defaultAlertRules)
		}
	}

	handlerClickCancelButton() {
		this.alertRuleModel.emit(false)
	}

	setAlertRuleSetting(alertRules: AlertRuleDTO) {
		const {
			heartRate,
			bodyTemperature,
			diastolicPressure,
			respirationRate,
			spO2,
			systolicPressure,
			bloodGlucose,
			exitBed
		} = alertRules
		this.heartRateCritical = [heartRate.minCritical, heartRate.maxCritical]
		this.heartRateNormal = [heartRate.minNormal, heartRate.maxNormal]
		this.bodyTemperatureCritical = [
			bodyTemperature.minCritical,
			bodyTemperature.maxCritical
		]
		this.bodyTemperatureNormal = [
			bodyTemperature.minNormal,
			bodyTemperature.maxNormal
		]
		this.diastolicPressureCritical = [
			diastolicPressure.minCritical,
			diastolicPressure.maxCritical
		]
		this.diastolicPressureNormal = [
			diastolicPressure.minNormal,
			diastolicPressure.maxNormal
		]
		this.respirationRateCritical = [
			respirationRate.minCritical,
			respirationRate.maxCritical
		]
		this.respirationRateNormal = [
			respirationRate.minNormal,
			respirationRate.maxNormal
		]
		this.spO2Critical = [spO2.minCritical, spO2.maxCritical]
		this.spO2Normal = [spO2.minNormal, spO2.maxNormal]
		this.systolicPressureCritical = [
			systolicPressure.minCritical,
			systolicPressure.maxCritical
		]
		this.systolicPressureNormal = [
			systolicPressure.minNormal,
			systolicPressure.maxNormal
		]
		this.bloodGlucoseCritical = [
			bloodGlucose.minCritical,
			bloodGlucose.maxCritical
		]
		this.bloodGlucoseNormal = [bloodGlucose.minNormal, bloodGlucose.maxNormal]
		this.monitorFromTime = monitorTime(exitBed.monitorFrom)
		this.monitorTillTime = monitorTime(exitBed.monitorTill)
	}

	setEntityDiffSetting(type: string) {
		const entityDiff: UpdateAlertRule | CreateAlertRule | any = {
			maxCriticalHeartRate: this.heartRateCritical[1],
			minCriticalHeartRate: this.heartRateCritical[0],
			maxNormalHeartRate: this.heartRateNormal[1],
			minNormalHeartRate: this.heartRateNormal[0],
			maxCriticalBodyTemperature: this.bodyTemperatureCritical[1],
			minCriticalBodyTemperature: this.bodyTemperatureCritical[0],
			maxNormalBodyTemperature: this.bodyTemperatureNormal[1],
			minNormalBodyTemperature: this.bodyTemperatureNormal[0],
			maxCriticalDiastolicPressure: this.diastolicPressureCritical[1],
			minCriticalDiastolicPressure: this.diastolicPressureCritical[0],
			maxNormalDiastolicPressure: this.diastolicPressureNormal[1],
			minNormalDiastolicPressure: this.diastolicPressureNormal[0],
			maxCriticalRespirationRate: this.respirationRateCritical[1],
			minCriticalRespirationRate: this.respirationRateCritical[0],
			maxNormalRespirationRate: this.respirationRateNormal[1],
			minNormalRespirationRate: this.respirationRateNormal[0],
			maxCriticalSpO2: this.spO2Critical[1],
			minCriticalSpO2: this.spO2Critical[0],
			maxNormalSpO2: this.spO2Normal[1],
			minNormalSpO2: this.spO2Normal[0],
			maxCriticalSystolicPressure: this.systolicPressureCritical[1],
			minCriticalSystolicPressure: this.systolicPressureCritical[0],
			maxNormalSystolicPressure: this.systolicPressureNormal[1],
			minNormalSystolicPressure: this.systolicPressureNormal[0],
			maxCriticalBloodGlucose: this.bloodGlucoseCritical[1],
			minCriticalBloodGlucose: this.bloodGlucoseCritical[0],
			maxNormalBloodGlucose: this.bloodGlucoseNormal[1],
			minNormalBloodGlucose: this.bloodGlucoseNormal[0],
			exitBedRiskMonitorFrom: monitorDecimalTime(this.monitorFromTime),
			exitBedRiskMonitorTill: monitorDecimalTime(this.monitorTillTime)
		}
		if (type === 'create') {
			entityDiff._name = `${this.patient.name} Alert Rule`
			entityDiff._templateId = 'c159c6bf-8aed-4965-b1cf-4b7015a19948'
			entityDiff._ownerOrganization = (this.patient as any)._ownerOrganization
		}
		return entityDiff
	}

	handlerClick() {
		if (!this.havePatientAlertRules) {
			this.operationsToAlertRule.emit({
				id: '',
				type: 'create',
				entityDiff: this.setEntityDiffSetting('create')
			})
		} else {
			this.operationsToAlertRule.emit({
				id: this.patient.patientAlertRules?.id,
				type: 'update',
				entityDiff: this.setEntityDiffSetting('update')
			})
		}
		this.handlerClickCancelButton()
	}
}
