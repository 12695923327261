import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { catchError, map, Observable, of, tap, throwError } from 'rxjs'
import { LoginAPIService } from 'biot-client-ums'
import { Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import {
	AuthStateInterface,
	LoginRequestInterface,
	LoginResponseInterface
} from '../../shared/model/auth.model'

export const authFeatureName = 'auth'

@StateRepository()
@State<AuthStateInterface>({
	name: authFeatureName,
	defaults: {
		accessJwt: null,
		refreshJwt: null,
		isLoading: false
	}
})
@Injectable()
export class AuthState extends NgxsDataRepository<AuthStateInterface> {
	constructor(
		private loginApiService: LoginAPIService,
		private router: Router
	) {
		super()
	}

	@Selector()
	static isLoading(state: AuthStateInterface): boolean {
		return state.isLoading
	}

	@Selector()
	static isAuthenticated(state: AuthStateInterface): boolean {
		return state.accessJwt != null
	}

	public isAuthenticated(): boolean {
		return this.snapshot.accessJwt != null
	}

	public accessToken(): string | undefined {
		return this.snapshot.accessJwt?.token
	}

	public refreshToken(): string | undefined {
		return this.snapshot.refreshJwt?.token
	}

	@DataAction()
	public refreshAccessToken(): Observable<string> {
		const refreshToken = this.snapshot.refreshJwt?.token

		if (refreshToken)
			return this.loginApiService
				.refreshToken({
					refreshToken: refreshToken
				})
				.pipe(
					map((res: LoginResponseInterface) => {
						this.handleLoginResponse(res)
						return res.accessJwt?.token!
					}),
					catchError((err) => {
						this.reset()
						throw err
					})
				)
		else
			return throwError(
				() => new HttpErrorResponse({ statusText: 'Missing refresh token' })
			)
	}

	@DataAction()
	public login(
		@Payload('req') req: LoginRequestInterface
	): Observable<LoginResponseInterface | null> {
		this.ctx.patchState({
			isLoading: true
		})
		return this.loginApiService.login(req).pipe(
			tap((res: LoginResponseInterface) => {
				this.handleLoginResponse(res)
			}),
			catchError(() => {
				this.reset()
				return of(null)
			}),
			tap(() => this.router.navigateByUrl('/'))
		)
	}

	private handleLoginResponse(res: LoginResponseInterface): void {
		this.ctx.patchState({
			accessJwt: res.accessJwt,
			refreshJwt: res.refreshJwt,
			isLoading: false
		})
	}
}
