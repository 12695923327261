/**
 * BioT Organization Service
 * APIs document (version: 7.66.0)
 *
 * The version of the OpenAPI document: 7.66.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http'
import { CustomHttpParameterCodec } from '../encoder'
import { Observable } from 'rxjs'

// @ts-ignore
import { ErrorResponse } from '../model/error-response'
// @ts-ignore
import { TokenOperationRequest } from '../model/token-operation-request'
// @ts-ignore
import { TokenOperationResponse } from '../model/token-operation-response'

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables'
import { Configuration } from '../configuration'

@Injectable({
  providedIn: 'root',
})
export class TemporaryTokenOperationAPIService {
  protected basePath = '/organization'
  public defaultHeaders = new HttpHeaders()
  public configuration = new Configuration()
  public encoder: HttpParameterCodec

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath
      }
      this.configuration.basePath = basePath
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec()
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    httpParams = this.addToHttpParamsRecursive(httpParams, value, key)
    return httpParams
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams
    }

    if (typeof value === 'object') {
      // @ts-ignore
      httpParams = httpParams.append(key, JSON.stringify(value))
    } else if (key != null) {
      httpParams = httpParams.append(key, value)
    } else {
      throw Error('key may not be null if value is not object or array')
    }
    return httpParams
  }

  /**
   * Execute token operation
   * Execute token operation  Execute operation by specifying a temp token instead of jwt token  This API is **public**.
   * @param token Temporary token for calling this API
   * @param operation Operation name
   * @param entityId The entity id of the operation
   * @param tokenOperationRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public executeTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    tokenOperationRequest: TokenOperationRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<TokenOperationResponse>
  public executeTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    tokenOperationRequest: TokenOperationRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<TokenOperationResponse>>
  public executeTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    tokenOperationRequest: TokenOperationRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<TokenOperationResponse>>
  public executeTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    tokenOperationRequest: TokenOperationRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling executeTokenOperation.'
      )
    }
    if (operation === null || operation === undefined) {
      throw new Error(
        'Required parameter operation was null or undefined when calling executeTokenOperation.'
      )
    }
    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling executeTokenOperation.'
      )
    }
    if (tokenOperationRequest === null || tokenOperationRequest === undefined) {
      throw new Error(
        'Required parameter tokenOperationRequest was null or undefined when calling executeTokenOperation.'
      )
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (operation !== undefined && operation !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>operation,
        'operation'
      )
    }
    if (entityId !== undefined && entityId !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>entityId,
        'entityId'
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      )
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      )
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    return this.httpClient.post<TokenOperationResponse>(
      `${this.configuration.basePath}/v1/tokens/${encodeURIComponent(
        String(token)
      )}`,
      tokenOperationRequest,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }

  /**
   * Validation token operation
   * Validation token operation  Validate that token, operation and entity ID are valid and not expired.  This API is **public**.
   * @param token Temporary token for calling this API
   * @param operation Operation name
   * @param entityId The entity id of the operation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validateTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>
  public validateTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>
  public validateTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>
  public validateTokenOperation(
    token: string,
    operation: string,
    entityId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling validateTokenOperation.'
      )
    }
    if (operation === null || operation === undefined) {
      throw new Error(
        'Required parameter operation was null or undefined when calling validateTokenOperation.'
      )
    }
    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling validateTokenOperation.'
      )
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (operation !== undefined && operation !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>operation,
        'operation'
      )
    }
    if (entityId !== undefined && entityId !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>entityId,
        'entityId'
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      )
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    return this.httpClient.get<any>(
      `${this.configuration.basePath}/v1/tokens/${encodeURIComponent(
        String(token)
      )}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    )
  }
}
