import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	HostListener,
	OnInit
} from '@angular/core'
import { Observable } from 'rxjs'
import { routingAnimation } from '../shared/animations/routing-animation'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Actions, Select } from '@ngxs/store'
import { ErrorState } from '../store/error/error.state'
import { AuthState } from '../store/auth/auth.state'
import { PreferenceState } from '../store/preference/preference.state'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment/moment'
import { registerLocaleData } from '@angular/common'
import en from '@angular/common/locales/en'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [routingAnimation],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
	@Select(ErrorState.errorMessage)
	errorMessage$: Observable<string | null>
	width: any

	@Select(AuthState.isAuthenticated)
	isAuthorized$: Observable<boolean>
	@Select(PreferenceState.language)
	language$: Observable<string>
	isCollapseMenu: boolean = true
	@HostBinding('@routingAnimation') private routing: any

	constructor(
		private actions$: Actions,
		private errorState: ErrorState,
		private notification: NzNotificationService,
		private preferenceState: PreferenceState,
		private translate: TranslateService
	) {
		translate.setDefaultLang('en')
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.width = document.body.clientWidth
	}

	ngOnInit(): void {
		this.initializeListeners()
		this.width = document.body.clientWidth
	}

	initializeListeners(): void {
		this.errorMessage$.subscribe((message: string | null) => {
			if (!message) return
			this.notification.create('error', 'Error', message)
			setTimeout(() => this.errorState.clear(), 5000)
		})

		this.language$.subscribe((data: string | null) => {
			if (data) {
				moment.locale(data)
				registerLocaleData(data)
				this.translate.use(data)
				return
			}
			this.preferenceState.setLanguage(
				// @ts-ignore
				navigator.language !== 'en' || navigator.language !== 'es'
					? 'en'
					: navigator.language
			)
		})
	}

	handlerCollapseMenuEmitter($event: boolean): void {
		this.preferenceState.setPreferenceIsCollapseMenu($event)
	}
}
