/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasicReferencedEntityDataUUIDId } from './basic-referenced-entity-data-uuidid';
import { BasicReferencedEntityDataStringId } from './basic-referenced-entity-data-string-id';
import { BasicTemplateData } from './basic-template-data';
import { ReferencedSideAttributeInformation } from './referenced-side-attribute-information';
import { SessionSummary } from './session-summary';


export interface SessionResponseV2 { 
    /**
     * A UUID formatted string
     */
    _id: string;
    _patient: BasicReferencedEntityDataUUIDId;
    _device: BasicReferencedEntityDataStringId;
    _ownerOrganization: BasicReferencedEntityDataUUIDId;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _startTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _endTime?: string;
    /**
     * The Sessions state
     */
    _state: SessionResponseV2.StateEnum;
    /**
     * What API initiated the session
     */
    _initiator: SessionResponseV2.InitiatorEnum;
    /**
     * The interval between measurements in milliseconds
     */
    _requiredMeasurementIntervalMilliseconds: number;
    _template?: BasicTemplateData;
    _referencers?: { [key: string]: ReferencedSideAttributeInformation; };
    _configuration?: { [key: string]: object; };
    _summary: SessionSummary;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _lastModifiedTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _creationTime: string;
    _sessionContext?: string;
}
export namespace SessionResponseV2 {
    export type StateEnum = 'ACTIVE' | 'DONE' | 'PAUSED' | 'ACTIVATING' | 'PAUSING' | 'RESUMING' | 'STOPPING';
    export const StateEnum = {
        Active: 'ACTIVE' as StateEnum,
        Done: 'DONE' as StateEnum,
        Paused: 'PAUSED' as StateEnum,
        Activating: 'ACTIVATING' as StateEnum,
        Pausing: 'PAUSING' as StateEnum,
        Resuming: 'RESUMING' as StateEnum,
        Stopping: 'STOPPING' as StateEnum
    };
    export type InitiatorEnum = 'REMOTE_CONTROL' | 'EXTERNAL';
    export const InitiatorEnum = {
        RemoteControl: 'REMOTE_CONTROL' as InitiatorEnum,
        External: 'EXTERNAL' as InitiatorEnum
    };
}


