import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { DeviceAPIService } from './api/device-api.service';
import { GroupAPIService } from './api/group-api.service';
import { HealthCheckAPIService } from './api/health-check-api.service';
import { LoginAPIService } from './api/login-api.service';
import { MFALoginAPIService } from './api/mfa-login-api.service';
import { PermissionAPIService } from './api/permission-api.service';
import { RoleAPIService } from './api/role-api.service';
import { SelfPermissionAPIService } from './api/self-permission-api.service';
import { SelfUserAPIService } from './api/self-user-api.service';
import { ServiceUserAPIService } from './api/service-user-api.service';
import { TemporaryTokenOperationAPIService } from './api/temporary-token-operation-api.service';
import { UserAPIService } from './api/user-api.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
