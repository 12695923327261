/**
 * BioT Measurement Service
 * APIs document (version: 1.34.5)
 *
 * The version of the OpenAPI document: 1.34.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetAggregatedMeasurementsRequest { 
    attributes: Array<string>;
    patientId: string;
    sourceEntityId?: string;
    sessionId?: string;
    startTime: string;
    endTime: string;
    binIntervalSeconds: GetAggregatedMeasurementsRequest.BinIntervalSecondsEnum;
    maxResult?: number;
}
export namespace GetAggregatedMeasurementsRequest {
    export type BinIntervalSecondsEnum = 1 | 3 | 6 | 15 | 30 | 60 | 120 | 300 | 1800 | 10800 | 21600 | 43200 | 86400 | 172800 | 604800;
    export const BinIntervalSecondsEnum = {
        NUMBER_1: 1 as BinIntervalSecondsEnum,
        NUMBER_3: 3 as BinIntervalSecondsEnum,
        NUMBER_6: 6 as BinIntervalSecondsEnum,
        NUMBER_15: 15 as BinIntervalSecondsEnum,
        NUMBER_30: 30 as BinIntervalSecondsEnum,
        NUMBER_60: 60 as BinIntervalSecondsEnum,
        NUMBER_120: 120 as BinIntervalSecondsEnum,
        NUMBER_300: 300 as BinIntervalSecondsEnum,
        NUMBER_1800: 1800 as BinIntervalSecondsEnum,
        NUMBER_10800: 10800 as BinIntervalSecondsEnum,
        NUMBER_21600: 21600 as BinIntervalSecondsEnum,
        NUMBER_43200: 43200 as BinIntervalSecondsEnum,
        NUMBER_86400: 86400 as BinIntervalSecondsEnum,
        NUMBER_172800: 172800 as BinIntervalSecondsEnum,
        NUMBER_604800: 604800 as BinIntervalSecondsEnum
    };
}


