import { Id } from './common'
import { PatientIdEmbed } from './patient'
import { DeviceIdEmbed } from './device.model'

export interface AlertId extends Id {}

export interface AlertIdEmbed extends Id {
	name: string
}

export enum AlertSubject {
	BodyTemperature = 'body_temperature',
	Device = 'device',
	DeviceBattery = 'device_battery',
	General = 'general',
	HeartRate = 'heart_rate',
	Posture = 'posture',
	RespirationRate = 'respiration_rate',
	SystolicPressure = 'systolicPressure',
	DiastolicPressure = 'diastolicPressure',
	ExitBedRisk = 'exit_bed_risk',
	BloodGlucose = 'blood_glucose',
	SPO2 = 'spo2'
}

export enum AlertStatus {
	Archived = 'archived',
	Closed = 'closed',
	Open = 'open',
	Snoozed = 'snoozed'
}

export enum AlertSeverity {
	Critical = 'critical',
	Major = 'major',
	Minor = 'minor',
	Notice = 'notice',
	Info = 'info'
}

export function alertSeverityComparator(
	a: AlertSeverity,
	b: AlertSeverity
): number {
	return (
		Object.values(AlertSeverity).indexOf(a) -
		Object.values(AlertSeverity).indexOf(b)
	)
}

export enum AlertResolution {
	Duplicate = 'duplicate',
	False = 'false',
	NA = 'na',
	Resolved = 'resolved'
}

export type UpdateAlertInterface = Pick<
	Partial<AlertDTO>,
	'severity' | 'status' | 'resolution' | 'snoozedUntilTime'
>

export interface AlertDTO extends AlertIdEmbed {
	creationTime: Date
	lastModifiedTime: Date
	subject: AlertSubject
	status: AlertStatus
	severity: AlertSeverity
	snoozedUntilTime: any
	resolution: AlertResolution | null
	resolvedBy: any
	patient: PatientIdEmbed | null | any
	alertedDevice: DeviceIdEmbed | null
}
