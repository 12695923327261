<section class='alert'>
  <nz-modal (nzOnCancel)='handlerClickCancelButton()' [(nzVisible)]='isVisible'>
    <div *nzModalTitle class='alert__header-title'>
      <p>{{'alertModal.resolving' | translate}}</p>
    </div>
    <div *nzModalContent>
      <div class='alert__patient-container'>
        <nz-avatar *ngIf='patient.avatar && patient.avatar.signedUrl; else imageName'
                   [nzSrc]='patient.avatar.signedUrl'
                   nzSize='large'
        ></nz-avatar>
        <ng-template #imageName>
          <nz-avatar [nzText]='patient.name | shortName' nzSize='default'></nz-avatar>
        </ng-template>
        <div class='alert__patient-name'>{{patient.firstName }} {{patient.lastName }}</div>
        <ul *ngIf='alerts.length > 1' class='alert__patient-alerts'>
          <li *ngFor='let alert of alerts'>
            <div>
              <label (click)='handlerAlertClick(alert)' [attr.alertID]='alert.id' nz-checkbox><i
                [attr.severity]='alert.severity'
                [nzType]='alert.subject | alertSubjectIcon'
                nz-icon></i> <span
                class='alert-name'>{{'alertSubject.' + alerts[0].subject | translate | titlecase}}</span></label>
            </div>
            <span class='alert-time'>{{  alert.lastModifiedTime | amTimeAgo : undefined: agoFormatter}}</span>
          </li>
        </ul>
        <ul *ngIf='alerts.length === 1' class='alert__patient-alerts'>
          <li>
            <div>
              <i
                [attr.severity]='alerts[0].severity'
                [nzType]='alerts[0].subject | alertSubjectIcon'
                nz-icon></i><span
              class='alert-name'>{{'alertSubject.' + alerts[0].subject | translate | titlecase}}</span></div>
            <span
              class='alert-time'>{{alerts[0].lastModifiedTime | amTimeAgo : undefined: agoFormatter}}</span>
          </li>
        </ul>
      </div>
      <div class='alert__dismiss-container'>
        <div class='alert__dismiss-title'>{{'alertModal.resolution.indicate' | translate}}</div>
        <div class='alert__dismiss-resolution'>
          <nz-radio-group [(ngModel)]='resolution' nzButtonStyle='solid'>
            <label nz-radio-button nzValue='false'>{{'alertModal.resolution.false' | translate}}</label>
            <label nz-radio-button nzValue='na'>{{'alertModal.resolution.na' | translate}}</label>
            <label nz-radio-button nzValue='duplicate'>{{'alertModal.resolution.done' | translate}}</label>
            <label nz-radio-button nzValue='snoozed'>{{'alertModal.resolution.mute' | translate}}</label>
          </nz-radio-group>
        </div>
        <div *ngIf='resolution === "snoozed"' class='alert__dismiss-resolution-time'>
          <div class='alert__dismiss-resolution-time-title'>{{'alertModal.hourIndicate' | translate}}</div>
          <nz-input-number-group [nzAddOnAfter]='addOnAfterTemplate' [nzAddOnBefore]='addOnBeforeTemplate'>
            <nz-input-number [(ngModel)]='timeValue' [nzMin]='1' [nzStep]='1'
                             class='input-aiomed'></nz-input-number>
          </nz-input-number-group>
          <ng-template #addOnAfterTemplate>
            <span (click)='increment("+")'>+</span>
          </ng-template>
          <ng-template #addOnBeforeTemplate>
            <span (click)='increment("-")'>-</span>
          </ng-template>
        </div>
      </div>
    </div>
    <div *nzModalFooter>
      <form nz-form nzLayout='vertical'>
        <button (click)='handlerClickCancelButton()' nz-button nzBlock
                nzType='primary'>{{'alertModal.button.cancel' | translate}}
        </button>
        <button (click)='submitForm()' class='btn-prime' nz-button nzBlock nzType='primary'
                type='submit'>{{'alertModal.button.submit' | translate}}
        </button>
      </form>
    </div>
  </nz-modal>
</section>
