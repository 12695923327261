import {
	HttpErrorResponse,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, switchMap, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { Router } from '@angular/router'
import { LoginAPIService } from 'biot-client-ums'
import { ErrorState } from '../../store/error/error.state'
import { AuthState } from '../../store/auth/auth.state'

@Injectable({
	providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private loginApiService: LoginAPIService,
		private errorState: ErrorState,
		private authState: AuthState
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		return next.handle(request).pipe(
			catchError((errorResponse: HttpErrorResponse) => {
				if (errorResponse.status === 401) {
					return this.handle401Error(request, next)
				} else {
					this.authState.reset()
					this.router.navigateByUrl('/login')
					this.errorState.setError(errorResponse.error)
					return throwError(errorResponse)
				}
			})
		)
	}

	addAuthenticationToken(request: HttpRequest<any>, token: string) {
		if (!token) {
			return request
		}

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		})
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		return this.authState.refreshAccessToken().pipe(
			switchMap((token) => {
				return next.handle(this.addAuthenticationToken(request, token))
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.authState.reset()
				this.errorState.setError(errorResponse.error)
				this.router.navigateByUrl('/login')
				return next.handle(request)
			})
		)
	}
}
