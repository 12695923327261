import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, of } from 'rxjs'
import { InsightDTO } from '../../shared/model/insight.model'

export const departmentFeatureName = 'insight'

@StateRepository()
@State<CollatableEntityCollections<InsightDTO>>({
	name: departmentFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class InsightState extends CollatableEntityCollectionsRepository<
	InsightDTO,
	EntityCollation
> {
	insights: InsightDTO[] = [
		{
			id: '1',
			patientId: '2f99df0a-4d9e-4cbb-b65e-1fe8497f3419',
			data: [
				{
					value: 'High risk of hospitalization in the upcoming 24hrs',
					time: '3hrs ago',
					checked: false
				},
				{
					value: 'Anticipating high blood pressure due to change',
					time: 'Yesterday at 17:02',
					checked: false
				}
			]
		},
		{
			id: '2',
			patientId: '7158e173-fa15-42e0-ab14-92b70dda21ef',
			data: [
				{
					value: 'High risk of hospitalization in the upcoming 24hrs',
					time: '3/1/23 at 17:02',
					checked: false
				}
			]
		}
	]

	constructor() {
		super()
	}

	@Selector()
	public static insights(
		state: CollatableEntityCollections<InsightDTO>
	): InsightDTO[] {
		return Object.values(state.entities)
	}

	@DataAction()
	public setInsights(): Observable<void> {
		this.upsertMany(this.insights)
		return of()
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
