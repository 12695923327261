import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core'
import { Observable, take } from 'rxjs'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Actions, Select } from '@ngxs/store'
import { UserState } from '../../../../../store/user/user.state'
import { UserInterface } from '../../../../../shared/model/user.model'
import { NotificationState } from '../../../../../store/notification/notification.state'
import { PatientState } from '../../../../../store/patient/patient.state'
import { PatientDTO } from '../../../../../shared/model/patient'
import { Router } from '@angular/router'

@UntilDestroy()
@Component({
	selector: 'aiomed-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
	@Input() type:
		| 'patients'
		| 'alerts'
		| 'devices'
		| 'patient'
		| 'all-patients' = 'patients'
	@Input() entitiesHaveAlertLength: number | any
	@Input() followEntitiesLength: number | any
	@Input() totalCount: number | any
	@Input() totalDisconnect: number | any
	@Input() entitiesMuteLength: number | any
	@Output('searchPatientEmitter') searchPatient = new EventEmitter()
	@Select(UserState.currentUser)
	user$: Observable<UserInterface | null>
	@Select(PatientState.searchPatient)
	searchPatient$: Observable<PatientDTO[]>
	textSearch: string = ''
	// title: string
	// searchName = ''
	// searchValue: string
	// changeInputValue$: Subject<string>
	// currentQueryType: Params
	// showHeader: boolean = true

	constructor(
		private actions$: Actions,
		private userState: UserState,
		private cdRef: ChangeDetectorRef,
		private notificationState: NotificationState,
		public router: Router
	) {
		// this.changeInputValue$ = new Subject<string>()
	}

	ngOnInit(): void {
		this.initializeListeners()
	}

	initializeListeners(): void {
		this.notificationState.receiveMessage()
		// this.router.events
		// 	.pipe(untilDestroyed(this), delay(500))
		// 	.subscribe((event) => {
		// 		if (event instanceof NavigationStart) {
		// 			this.setRouteTypeQuerySetting(this.route.snapshot.firstChild?.data)
		// 		}
		// 	})
		// this.route.queryParams
		// 	.pipe(untilDestroyed(this))
		// 	.subscribe((params: Params) => {
		// 		this.setRouteSetting(params)
		// 		if (!this.searchValue) return
		// 		this.setKeywordQueryParams(this.searchValue)
		// 	})
		// this.changeInputValueListener()
		this.user$.pipe(take(2)).subscribe((user: UserInterface | null) => {
			if (!user) return
			this.notificationState.requestPermission(user)
		})
	}

	// setRouteSetting(params: Params): void {
	// 	const queryKeyword = params['keyword']
	// 	this.setRouteKeywordQuerySetting(queryKeyword)
	// 	this.cdRef.detectChanges()
	// }

	// setRouteKeywordQuerySetting(queryKeyword: string | any) {
	// 	if (queryKeyword) {
	// 		this.changeInputValue$.next(queryKeyword)
	// 	}
	// }

	// changeInputValueListener(): void {
	// 	this.changeInputValue$
	// 		.pipe(debounceTime(1000), distinctUntilChanged(), untilDestroyed(this))
	// 		.subscribe((data: string) => {
	// 			this.searchValue = data
	// 			this.patientState.setFreeTextFilter(data)
	// 			this.deviceState.setFreeTextFilter(data)
	// 			this.alertState.setFreeTextFilter(data)
	// 			this.setKeywordQueryParams(data)
	// 			if (data.length) return
	// 			this.deleteKeywordQueryParams()
	// 		})
	// }

	// public setKeywordQueryParams(data: string) {
	// 	this.searchValue = data
	// 	const queryParams: Params = { keyword: data }
	//
	// 	this.router.navigate([], {
	// 		relativeTo: this.activatedRoute,
	// 		queryParams: queryParams,
	// 		queryParamsHandling: 'merge'
	// 	})
	// 	this.cdRef.detectChanges()
	// }
	//
	// public deleteKeywordQueryParams() {
	// 	this.router.navigate([], {
	// 		queryParams: {
	// 			...this.currentQueryType
	// 		}
	// 	})
	// }
}
