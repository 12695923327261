import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import {
	GenericEntityAPIService,
	SearchResponseGenericEntityResponse,
	UpdateGenericEntityRequest
} from 'biot-client-generic-entity'
import { Injectable } from '@angular/core'
import { mergeMap, Observable, of, tap } from 'rxjs'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import {
	CreatePatientLog,
	PatientLogDTO,
	UpdatePatientLogInterface
} from '../../shared/model/patient-log.model'

export const patientLogFeatureName = 'patientLog'

@StateRepository()
@State<CollatableEntityCollections<PatientLogDTO>>({
	name: patientLogFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class PatientLogState extends CollatableEntityCollectionsRepository<
	PatientLogDTO,
	EntityCollation
> {
	constructor(private genericEntityAPIService: GenericEntityAPIService) {
		super()
	}

	@Selector()
	public static patientLog(
		state: CollatableEntityCollections<PatientLogDTO>
	): PatientLogDTO[] {
		return Object.values(state.entities)
	}

	private static toPatientLogDTO(res: PatientLogDTO): PatientLogDTO {
		return {
			...res,
			id: res._id,
			addressee: res.addressee,
			alert: res.alert,
			author: res.author,
			logEntryPatient: res.logEntryPatient,
			logEntryType: res.logEntryType,
			read: res.read,
			text: res.text,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime
		}
	}

	@DataAction()
	public createPatientLog(@Payload('patientLog') patientLog: CreatePatientLog) {
		return this.genericEntityAPIService.createGenericEntity(patientLog).pipe(
			tap((res) => {
				this.upsertOne(PatientLogState.toPatientLogDTO(res as PatientLogDTO))
			})
		)
	}

	@DataAction()
	public updatePatientLog(
		@Payload('entityId') id: string,
		@Payload('update') entityDiff: UpdatePatientLogInterface
	): Observable<void> {
		return this.genericEntityAPIService
			.updateGenericEntity(id, entityDiff as UpdateGenericEntityRequest)
			.pipe(
				mergeMap(() => of()),
				mapToVoid()
			)
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					_templateId: {
						// @ts-ignore
						in: ['ff831db9-94d3-455e-bb6d-0831ed8535ea']
					},
					...(ids
						? {
								_id: {
									// @ts-ignore
									in: ids
								}
						  }
						: null)
				}
			})
			.pipe(
				tap((res: SearchResponseGenericEntityResponse) => {
					this.upsertAllAlertRules(res)
				}),
				mapToVoid()
			)
	}

	private upsertAllAlertRules(res: SearchResponseGenericEntityResponse) {
		const patientLog = res.data.map((e) =>
			PatientLogState.toPatientLogDTO(e as PatientLogDTO)
		)
		this.upsertMany(patientLog)
	}
}
