/**
 * BioT Organization Service
 * APIs document (version: 7.66.0)
 *
 * The version of the OpenAPI document: 7.66.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddressUpdateRequest { 
    /**
     * A 2-letter country code defined in ISO-3166
     */
    countryCode?: string;
    /**
     * A state
     */
    state?: string;
    /**
     * A city
     */
    city?: string;
    /**
     * A zipcode
     */
    zipCode?: string;
    /**
     * An address
     */
    address1?: string;
    /**
     * An address\'s specifics
     */
    address2?: string;
}

