export * from './device-api.service';
import { DeviceAPIService } from './device-api.service';
export * from './group-api.service';
import { GroupAPIService } from './group-api.service';
export * from './health-check-api.service';
import { HealthCheckAPIService } from './health-check-api.service';
export * from './login-api.service';
import { LoginAPIService } from './login-api.service';
export * from './mfa-login-api.service';
import { MFALoginAPIService } from './mfa-login-api.service';
export * from './permission-api.service';
import { PermissionAPIService } from './permission-api.service';
export * from './role-api.service';
import { RoleAPIService } from './role-api.service';
export * from './self-permission-api.service';
import { SelfPermissionAPIService } from './self-permission-api.service';
export * from './self-user-api.service';
import { SelfUserAPIService } from './self-user-api.service';
export * from './service-user-api.service';
import { ServiceUserAPIService } from './service-user-api.service';
export * from './temporary-token-operation-api.service';
import { TemporaryTokenOperationAPIService } from './temporary-token-operation-api.service';
export * from './user-api.service';
import { UserAPIService } from './user-api.service';
export const APIS = [DeviceAPIService, GroupAPIService, HealthCheckAPIService, LoginAPIService, MFALoginAPIService, PermissionAPIService, RoleAPIService, SelfPermissionAPIService, SelfUserAPIService, ServiceUserAPIService, TemporaryTokenOperationAPIService, UserAPIService];
