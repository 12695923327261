/**
 * BioT User Management Service
 * APIs document (version: 6.17.1)
 *
 * The version of the OpenAPI document: 6.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionBasicInfo } from './permission-basic-info';


export interface RoleResponse { 
    name: string;
    description?: string;
    id?: string;
    type?: RoleResponse.TypeEnum;
    permissions?: Set<PermissionBasicInfo>;
    creationTime?: string;
    lastModifiedTime?: string;
}
export namespace RoleResponse {
    export type TypeEnum = 'INTERNAL' | 'BUILT_IN' | 'INTERNAL_SERVICE_USER' | 'CUSTOM';
    export const TypeEnum = {
        Internal: 'INTERNAL' as TypeEnum,
        BuiltIn: 'BUILT_IN' as TypeEnum,
        InternalServiceUser: 'INTERNAL_SERVICE_USER' as TypeEnum,
        Custom: 'CUSTOM' as TypeEnum
    };
}


