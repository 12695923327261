import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { SignsIllnessDTO } from '../../shared/model/simptom-condition.model'
import { SignsIllnessService } from './signsIllness.service'
import { Observable, tap } from 'rxjs'
import { mapToVoid } from '@angular-ru/cdk/rxjs'

export const symptomConditionFeatureName = 'signsIllness'

@StateRepository()
@State<SignsIllnessDTO>({
	name: symptomConditionFeatureName,
	defaults: {
		symptoms: [],
		conditions: []
	}
})
@Injectable()
export class SignsIllnessState extends NgxsDataRepository<SignsIllnessDTO> {
	constructor(private symptomConditionService: SignsIllnessService) {
		super()
	}

	@Selector()
	static signsIllness(state: SignsIllnessDTO): SignsIllnessDTO {
		return state
	}

	@DataAction()
	setSignsIllness(): Observable<void> {
		return this.symptomConditionService.getSignsIllnessJson().pipe(
			tap((data) => {
				this.ctx.patchState({
					// @ts-ignore
					symptoms: data[0],
					// @ts-ignore
					conditions: data[1]
				})
			}),
			mapToVoid()
		)
	}
}
