import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { forkJoin } from 'rxjs'

@Injectable()
export class SignsIllnessService {
	constructor(private http: HttpClient) {}

	getSignsIllnessJson() {
		return forkJoin(
			this.http.get('/assets/resources/symptoms.json'),
			this.http.get('/assets/resources/conditions.json')
		)
	}
}
