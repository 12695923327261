/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TopicInfo { 
    actionEnum?: TopicInfo.ActionEnumEnum;
    toDevice?: string;
    fromDevice?: string;
}
export namespace TopicInfo {
    export type ActionEnumEnum = 'STATUS' | 'MEASUREMENT' | 'TOKEN';
    export const ActionEnumEnum = {
        Status: 'STATUS' as ActionEnumEnum,
        Measurement: 'MEASUREMENT' as ActionEnumEnum,
        Token: 'TOKEN' as ActionEnumEnum
    };
}


