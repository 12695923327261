import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators'
import { State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { NotificationDTO } from '../../shared/model/norification.model'
import { AngularFireMessaging } from '@angular/fire/compat/messaging'
import { UserState } from '../user/user.state'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { UserInterface } from '../../shared/model/user.model'
import { AlertState } from '../alert/alert.state'

export const notificationFeatureName = 'notification'

@StateRepository()
@State<NotificationDTO>({
	name: notificationFeatureName
})
@Injectable()
export class NotificationState extends NgxsDataRepository<NotificationDTO> {
	constructor(
		private angularFireMessaging: AngularFireMessaging,
		private userState: UserState,
		private alertState: AlertState,
		private router: Router,
		private notification: NzNotificationService
	) {
		super()
	}

	@DataAction()
	public requestPermission(user: UserInterface): void {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('./firebase-messaging-sw.js')
				.then(() => {
					Notification.requestPermission().then((result) => {
						if (result === 'granted') {
							this.getMessageToken(user)
						} else if (result === 'denied') {
							// @ts-ignore
							// this.userState.updateUser({
							// 	messagingToken: ''
							// })
						}
					})
				})
				.catch(function (err) {
					console.log('Service worker registration failed, error:', err)
				})
		}
	}

	@DataAction()
	public receiveMessage() {
		this.angularFireMessaging.onMessage(({ data, notification }) => {
			const alertSubject = `${data.subject
				.split('_')
				.join(' ')
				.toUpperCase()} alert`
			this.alertState.loadPatientAlerts()
			const n = new Notification(data.notification.title, {
				body: alertSubject
			})
			n.onclick = () => this.router.navigateByUrl(`patient/${data.patientId}`)
			this.notification.create('warning', notification.title, alertSubject, {
				nzDuration: 0,
				nzPauseOnHover: false
			})
		})
	}

	getMessageToken(user: UserInterface): void {
		this.angularFireMessaging.getToken.subscribe((token) => {
			if (!token || user.messagingToken === token) {
				return
			}
			// @ts-ignore
			// this.userState.updateUser({
			// 	messagingToken: token
			// })
		})
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	protected setPaginationSetting(type: string | undefined): Observable<void> {
		return EMPTY
	}
}
