/**
 * BioT User Management Service
 * APIs document (version: 6.17.1)
 *
 * The version of the OpenAPI document: 6.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PermissionResponse { 
    id?: string;
    type?: PermissionResponse.TypeEnum;
    creationTime?: string;
    lastModifiedTime?: string;
}
export namespace PermissionResponse {
    export type TypeEnum = 'BACK_END' | 'FRONT_END';
    export const TypeEnum = {
        BackEnd: 'BACK_END' as TypeEnum,
        FrontEnd: 'FRONT_END' as TypeEnum
    };
}


