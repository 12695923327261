/**
 * BioT User Management Service
 * APIs document (version: 6.17.1)
 *
 * The version of the OpenAPI document: 6.17.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupObject } from './group-object';


export interface UserResponse { 
    /**
     * user\'s username. If the credential type is OTP, should be a valid phone (An E.164 formatted phone number e.g +12345678901)
     */
    username: string;
    id?: string;
    ownerOrganizationId?: string;
    groups?: Set<GroupObject>;
    locked?: boolean;
    active?: boolean;
    mfa?: boolean;
    loginTimestamp?: string;
    credentialType?: UserResponse.CredentialTypeEnum;
    userIdPs?: Set<UserResponse.UserIdPsEnum>;
}
export namespace UserResponse {
    export type CredentialTypeEnum = 'PASSWORD' | 'OTP';
    export const CredentialTypeEnum = {
        Password: 'PASSWORD' as CredentialTypeEnum,
        Otp: 'OTP' as CredentialTypeEnum
    };
    export type UserIdPsEnum = 'UMS' | 'ADFS';
    export const UserIdPsEnum = {
        Ums: 'UMS' as UserIdPsEnum,
        Adfs: 'ADFS' as UserIdPsEnum
    };
}


