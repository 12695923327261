export * from './built-in-templates-api.service';
import { BuiltInTemplatesAPIService } from './built-in-templates-api.service';
export * from './caregiver-api.service';
import { CaregiverAPIService } from './caregiver-api.service';
export * from './health-check-api.service';
import { HealthCheckAPIService } from './health-check-api.service';
export * from './internal-template-api.service';
import { InternalTemplateAPIService } from './internal-template-api.service';
export * from './invitation-api.service';
import { InvitationAPIService } from './invitation-api.service';
export * from './organization-api.service';
import { OrganizationAPIService } from './organization-api.service';
export * from './organization-user-api.service';
import { OrganizationUserAPIService } from './organization-user-api.service';
export * from './overview-api.service';
import { OverviewAPIService } from './overview-api.service';
export * from './patient-api.service';
import { PatientAPIService } from './patient-api.service';
export * from './temporary-token-operation-api.service';
import { TemporaryTokenOperationAPIService } from './temporary-token-operation-api.service';
export * from './user-api.service';
import { UserAPIService } from './user-api.service';
export const APIS = [BuiltInTemplatesAPIService, CaregiverAPIService, HealthCheckAPIService, InternalTemplateAPIService, InvitationAPIService, OrganizationAPIService, OrganizationUserAPIService, OverviewAPIService, PatientAPIService, TemporaryTokenOperationAPIService, UserAPIService];
