import { Id } from './common'
import { AlertDTO, AlertSeverity } from './alert'
import { ObservationFields } from './patient-observation'
import { SortModel, SortType } from './sort.model'
import { cloneDeep, orderBy } from 'lodash-es'

export interface DeviceId extends Id {}

export interface DeviceIdEmbed extends Id {
	name: string
	manufacturerId?: string
}

export enum DeviceModality {
	BedMounted = 'bed_mounted',
	Implantable = 'implantable',
	SurfaceMounted = 'surface_mounted',
	WallMounted = 'wall_mounted',
	Wearable = 'wearable'
}

export enum DeviceModel {
	BiobeatWatch = 'biobeat_watch',
	BodiTrak2 = 'boditrak2',
	Dexton6 = 'dexton6',
	LifeSignalsPatch = 'lifesignals_patch',
	LinetSafeSense = 'linet_safesense'
}

export type DeviceDataUpdate = { [key in ObservationFields]: number } & {
	timestamp: Date
	deviceId: string
	context: string
}

export interface DeviceUpdateMqttMessage {
	metadata: {
		timestamp: number
		deviceId: string
		context: string
	}
	data: { [key in ObservationFields]: number }
}

export interface DeviceDTO extends DeviceIdEmbed {
	modality: DeviceModality
	model: DeviceModel
	serialNumber: string
	patient:
		| {
				id: string
				name: string
				room: string
		  }
		| any
	isConnected: boolean
	alerts?: AlertDTO[]
	batteryLevel: number | null
	statusInformation: string | null
	lastMeasurementTime: string | Date | null
	lastStatusUpdate: string | Date | null
}

export interface DeviceInterface extends DeviceDTO {
	alerts: AlertDTO[]
	maxAlertSeverity: AlertSeverity | null
	isTransmitting: boolean
	// patient?: PatientInterface
}

export function deviceSort(
	sort: SortModel[],
	devices: DeviceInterface[]
): DeviceInterface[] {
	if (!sort.length) return devices
	const sortName = sort.find((el) => el.prop === SortType.Name)
	const sortConnection = sort.find((el) => el.prop === SortType.Connection)
	let tmpDevices: any = cloneDeep(devices)
	if (sortName) {
		tmpDevices = orderBy(devices, 'patient.name', sortName.order)
	} else if (sortConnection) {
		if (sortConnection.order == 'asc') {
			// @ts-ignore
			tmpDevices = tmpDevices.sort((value) => {
				return value.isConnected || value.isTransmitting ? -1 : 1
			})
		} else {
			tmpDevices = devices.sort((value) => {
				return value.isConnected || value.isTransmitting ? 1 : -1
			})
		}
	}
	return tmpDevices
}
