import { FormGroup } from '@angular/forms'

export const cloneObj = (obj: any): any => {
	return JSON.parse(JSON.stringify(obj))
}
export const checkFormFields = (form: FormGroup) => {
	Object.values(form.controls).forEach((control) => {
		if (control.invalid) {
			control.markAsDirty()
			control.updateValueAndValidity({ onlySelf: true })
		}
	})
}
export const innitCustomScroll = (): void => {
	//does not have time to initialize house tree
	// setTimeout(
	// 	// @ts-ignore
	// 	() => Scrollbar.init(document.querySelector('.ant-table-tbody')),
	// 	0
	// )
}
export const subtractHours = (numOfHours: number, date = new Date()): Date => {
	return new Date(date.valueOf() - numOfHours * 60 * 60 * 1000)
}
export const innitAddProgressCustomClass = (): void => {
	//does not have time to initialize house tree
	setTimeout(() => {
		// @ts-ignore
		document.querySelector('.ant-progress').classList.add('custom-progress')
	}, 1500)
}

export const base64ToFloat32Array = (base64: string): number[] => {
	const uint8Array: Uint8Array = Uint8Array.from(atob(base64), (c) =>
		c.charCodeAt(0)
	)

	const dv = new DataView(uint8Array.buffer)
	const bytesPerNumber = 4
	let res = []
	for (let i = 0; i < dv.byteLength; i += bytesPerNumber) {
		res.push(dv.getFloat32(i))
	}
	return res
}

export const separator: string[] | any = (xs: string, s: number) =>
	xs.length ? [xs.slice(0, s), ...separator(xs.slice(s), s)] : []

export const dataShaping = (date: string | Date, frequency: number): string => {
	const dataShaping = new Date(date).getTime() - 1000 / frequency
	return new Date(dataShaping).toISOString()
}
