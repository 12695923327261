/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasicReferencedEntityDataUUIDId } from './basic-referenced-entity-data-uuidid';
import { BasicReferencedEntityDataStringId } from './basic-referenced-entity-data-string-id';
import { BasicTemplateData } from './basic-template-data';
import { ReferencedSideAttributeInformation } from './referenced-side-attribute-information';


export interface CommandResponse { 
    /**
     * A UUID formatted string
     */
    _id: string;
    _device: BasicReferencedEntityDataStringId;
    _ownerOrganization: BasicReferencedEntityDataUUIDId;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _startTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _endTime?: string;
    /**
     * The Action state
     */
    _state: CommandResponse.StateEnum;
    /**
     * Error message
     */
    _errorMessage?: string;
    /**
     * Is the template support stop command
     */
    _supportStop: boolean;
    /**
     * The timeout of the command in seconds
     */
    _timeoutInSeconds: number;
    _template?: BasicTemplateData;
    _referencers?: { [key: string]: ReferencedSideAttributeInformation; };
    _configuration?: { [key: string]: object; };
    _summary?: { [key: string]: object; };
    _status?: { [key: string]: object; };
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _lastModifiedTime: string;
    /**
     * A date-time without a time-zone in the ISO-8601 calendar system
     */
    _creationTime: string;
}
export namespace CommandResponse {
    export type StateEnum = 'STARTING' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED' | 'TIMEOUT' | 'ABORTED';
    export const StateEnum = {
        Starting: 'STARTING' as StateEnum,
        InProgress: 'IN_PROGRESS' as StateEnum,
        Completed: 'COMPLETED' as StateEnum,
        Failed: 'FAILED' as StateEnum,
        Timeout: 'TIMEOUT' as StateEnum,
        Aborted: 'ABORTED' as StateEnum
    };
}


