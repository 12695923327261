/**
 * BioT Measurement Service
 * APIs document (version: 1.34.5)
 *
 * The version of the OpenAPI document: 1.34.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VersionResponse { 
    version?: string;
    springVersion?: string;
}

