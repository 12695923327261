/**
 * BioT Measurement Service
 * APIs document (version: 1.34.5)
 *
 * The version of the OpenAPI document: 1.34.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import {
	HttpClient,
	HttpContext,
	HttpEvent,
	HttpHeaders,
	HttpParameterCodec,
	HttpParams,
	HttpResponse
} from '@angular/common/http'
import { CustomHttpParameterCodec } from '../encoder'
import { Observable } from 'rxjs'

// @ts-ignore
import { ErrorResponse } from '../model/error-response'
// @ts-ignore
import { GetAggregatedMeasurementsRequest } from '../model/get-aggregated-measurements-request'
// @ts-ignore
import { GetAggregatedMeasurementsResponse } from '../model/get-aggregated-measurements-response'
// @ts-ignore
import { GetRawMeasurementsRequest } from '../model/get-raw-measurements-request'
// @ts-ignore
import { GetRawMeasurementsResponse } from '../model/get-raw-measurements-response'

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables'
import { Configuration } from '../configuration'

@Injectable({
	providedIn: 'root'
})
export class MeasurementsAPIService {
	public defaultHeaders = new HttpHeaders()
	public configuration = new Configuration()
	public encoder: HttpParameterCodec
	protected basePath = '/measurement'

	constructor(
		protected httpClient: HttpClient,
		@Optional() @Inject(BASE_PATH) basePath: string,
		@Optional() configuration: Configuration
	) {
		if (configuration) {
			this.configuration = configuration
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath
			}
			this.configuration.basePath = basePath
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec()
	}

	/**
	 * Get aggregated measurements
	 * Get aggregated measurements    Get all measurements for attributes and time  ### The below table describes the request parameters:  | Parameter          | Type          | Mandatory | Description                                                   |             |--------------------|---------------|-----------|---------------------------------------------------------------|              | attributes | List&lt;String&gt; | At least one | List of wanted attributes from the measurement table  | patientId | String | true | The patient\&#39;s ID  | sourceEntityId | String | false | The source entity ID - The source who entered the measurements. Can be device, doctor, etc. If not sent - return all sources  | sessionId | String | false | The session Id. If not sent - return all sessions  | startTime | DateTime | true | The wanted start time for the historical measurements. In ISO8601 format  | endTime | DateTime | true | The wanted end time for the historical measurements. In ISO8601 format  | binIntervalSeconds | Number | true | This number define the aggregation time in seconds. Meaning if 6 is provided, it will aggregate all measurements in 6 seconds interval  | maxResult | Number | false | Defines the maximum number of results. Default is 1000     This API requires **MEASUREMENT_GET_MEASUREMENTS** permission(s).
	 * @param flatRequest
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getMeasurements(
		flatRequest: GetAggregatedMeasurementsRequest,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<GetAggregatedMeasurementsResponse>

	public getMeasurements(
		flatRequest: GetAggregatedMeasurementsRequest,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<GetAggregatedMeasurementsResponse>>

	public getMeasurements(
		flatRequest: GetAggregatedMeasurementsRequest,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<GetAggregatedMeasurementsResponse>>

	public getMeasurements(
		flatRequest: GetAggregatedMeasurementsRequest,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (flatRequest === null || flatRequest === undefined) {
			throw new Error(
				'Required parameter flatRequest was null or undefined when calling getMeasurements.'
			)
		}

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
		if (flatRequest !== undefined && flatRequest !== null) {
			localVarQueryParameters = this.addToHttpParams(
				localVarQueryParameters,
				<any>flatRequest,
				'flatRequest'
			)
		}

		let localVarHeaders = this.defaultHeaders

		let localVarCredential: string | undefined
		// authentication (bearerAuth) required
		localVarCredential = this.configuration.lookupCredential('bearerAuth')
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set(
				'Authorization',
				'Bearer ' + localVarCredential
			)
		}

		let localVarHttpHeaderAcceptSelected: string | undefined =
			options && options.httpHeaderAccept
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept auth-headers
			const httpHeaderAccepts: string[] = ['application/json']
			localVarHttpHeaderAcceptSelected =
				this.configuration.selectHeaderAccept(httpHeaderAccepts)
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set(
				'Accept',
				localVarHttpHeaderAcceptSelected
			)
		}

		let localVarHttpContext: HttpContext | undefined =
			options && options.context
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext()
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json'
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text'
			} else if (
				this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
			) {
				responseType_ = 'json'
			} else {
				responseType_ = 'blob'
			}
		}

		return this.httpClient.get<GetAggregatedMeasurementsResponse>(
			`${this.configuration.basePath}/v1/measurements/aggregated`,
			{
				context: localVarHttpContext,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		)
	}

	/**
	 * Get raw measurements
	 * Get raw measurements    Get all measurements for attributes and time  ### The below table describes the request parameters:  | Parameter          | Type          | Mandatory | Description                                                   |             |--------------------|---------------|-----------|---------------------------------------------------------------|              | attributes | List&lt;String&gt; | At least one | List of wanted attributes from the measurement table  | patientId | String | true | The patient\&#39;s ID  | sourceEntityId | String | false | The source entity ID - The source who entered the measurements. Can be device, doctor, etc. If not sent - return all sources  | sessionId | String | false | The session Id. If not sent - return all sessions  | startTime | DateTime | true | The wanted start time for the historical measurements. In ISO8601 format  | endTime | DateTime | true | The wanted end time for the historical measurements. In ISO8601 format  | maxResult | Number | false | Defines the maximum number of results. Default is 1000     This API requires **MEASUREMENT_GET_RAW_MEASUREMENTS** permission(s).
	 * @param flatRequest
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public getRawMeasurements(
		flatRequest: GetRawMeasurementsRequest,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<GetRawMeasurementsResponse>

	public getRawMeasurements(
		flatRequest: GetRawMeasurementsRequest,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<GetRawMeasurementsResponse>>

	public getRawMeasurements(
		flatRequest: GetRawMeasurementsRequest,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<GetRawMeasurementsResponse>>

	public getRawMeasurements(
		flatRequest: GetRawMeasurementsRequest,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (flatRequest === null || flatRequest === undefined) {
			throw new Error(
				'Required parameter flatRequest was null or undefined when calling getRawMeasurements.'
			)
		}

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
		if (flatRequest !== undefined && flatRequest !== null) {
			localVarQueryParameters = this.addToHttpParams(
				localVarQueryParameters,
				<any>flatRequest,
				'flatRequest'
			)
		}

		let localVarHeaders = this.defaultHeaders

		let localVarCredential: string | undefined
		// authentication (bearerAuth) required
		localVarCredential = this.configuration.lookupCredential('bearerAuth')
		if (localVarCredential) {
			localVarHeaders = localVarHeaders.set(
				'Authorization',
				'Bearer ' + localVarCredential
			)
		}

		let localVarHttpHeaderAcceptSelected: string | undefined =
			options && options.httpHeaderAccept
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept auth-headers
			const httpHeaderAccepts: string[] = ['application/json']
			localVarHttpHeaderAcceptSelected =
				this.configuration.selectHeaderAccept(httpHeaderAccepts)
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set(
				'Accept',
				localVarHttpHeaderAcceptSelected
			)
		}

		let localVarHttpContext: HttpContext | undefined =
			options && options.context
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext()
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json'
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text'
			} else if (
				this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
			) {
				responseType_ = 'json'
			} else {
				responseType_ = 'blob'
			}
		}

		return this.httpClient.get<GetRawMeasurementsResponse>(
			`${this.configuration.basePath}/v1/measurements/raw`,
			{
				context: localVarHttpContext,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress
			}
		)
	}

	// @ts-ignore
	private addToHttpParams(
		httpParams: HttpParams,
		value: any,
		key?: string
	): HttpParams {
		if (typeof value === 'object' && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value)
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key)
		}
		return httpParams
	}

	private addToHttpParamsRecursive(
		httpParams: HttpParams,
		value?: any,
		key?: string
	): HttpParams {
		if (value == null) {
			return httpParams
		}

		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				;(value as any[]).forEach(
					(elem) =>
						(httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
				)
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(
						key,
						(value as Date).toISOString().substr(0, 10)
					)
				} else {
					throw Error('key may not be null if value is Date')
				}
			} else {
				Object.keys(value).forEach(
					(k) =>
						(httpParams = this.addToHttpParamsRecursive(
							httpParams,
							value[k],
							key != null ? `${key}.${k}` : k
						))
				)
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value)
		} else {
			throw Error('key may not be null if value is not object or array')
		}
		return httpParams
	}
}
