import { Pipe, PipeTransform } from '@angular/core'
import { AlertSubject } from '../../../shared/model/alert'

@Pipe({
	name: 'alertSubjectUnit'
})
export class AlertSubjectUnitPipe implements PipeTransform {
	AlertSubject = AlertSubject

	transform(data: any, ...args: unknown[]): string {
		if (!data) return ''
		if (data === AlertSubject.HeartRate) {
			return 'BMP'
		} else if (data === AlertSubject.RespirationRate) {
			return 'RPM'
		} else if (data === AlertSubject.BodyTemperature) {
			return '°C'
		} else if (
			data === AlertSubject.SystolicPressure ||
			data === AlertSubject.DiastolicPressure
		) {
			return 'mmHg'
		} else if (data === AlertSubject.SPO2) {
			return '%'
		} else if (data === AlertSubject.BloodGlucose) {
			return 'mg/dL'
		}
		return ''
	}
}
