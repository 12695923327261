/**
 * BioT Organization Service
 * APIs document (version: 7.66.0)
 *
 * The version of the OpenAPI document: 7.66.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address'
import { BasicReferencedEntityDataUUIDId } from './basic-referenced-entity-data-uuidid'
import { BasicTemplateData } from './basic-template-data'
import { ReferencedSideAttributeInformation } from './referenced-side-attribute-information'
import { Name } from './name'

export interface UserResponse {
	/**
	 * The user type
	 */
	_userType?: UserResponse.UserTypeEnum
	/**
	 * A UUID formatted string
	 */
	_id: string
	/**
	 * A date-time without a time-zone in the ISO-8601 calendar system
	 */
	_lastModifiedTime: string
	/**
	 * A date-time without a time-zone in the ISO-8601 calendar system
	 */
	_creationTime: string
	/**
	 * Has invitation been accepted
	 */
	_invitationAccepted: boolean
	_template?: BasicTemplateData
	_referencers?: { [key: string]: ReferencedSideAttributeInformation }
	_ownerOrganization: BasicReferencedEntityDataUUIDId
	_name: Name
	_address?: Address
	/**
	 * A java locale supported by the java runtime environment
	 */
	_locale?: string
	/**
	 * A description, can be very long
	 */
	_description?: string
	/**
	 * Is the user enabled
	 */
	_enabled?: boolean
	/**
	 * An email address in the RFC-5322 specification
	 */
	_email: string
	/**
	 * An E.164 formatted phone number
	 */
	_phone?: string
	/**
	 * A persons gender
	 */
	_gender?: UserResponse.GenderEnum
	/**
	 * A date without a time-zone in the ISO-8601 calendar system
	 */
	_dateOfBirth?: string
}

export namespace UserResponse {
	export type UserTypeEnum = 'organization-user' | 'caregiver' | 'patient'
	export const UserTypeEnum = {
		OrganizationUser: 'organization-user' as UserTypeEnum,
		Caregiver: 'caregiver' as UserTypeEnum,
		Patient: 'patient' as UserTypeEnum
	}
	export type GenderEnum = 'MALE' | 'FEMALE' | 'UNDISCLOSED'
	export const GenderEnum = {
		Male: 'MALE' as GenderEnum,
		Female: 'FEMALE' as GenderEnum,
		Undisclosed: 'UNDISCLOSED' as GenderEnum
	}
}
