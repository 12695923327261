import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import {
	GenericEntityAPIService,
	GenericEntityResponse,
	SearchResponseGenericEntityResponse,
	UpdateGenericEntityRequest
} from 'biot-client-generic-entity'
import { Injectable } from '@angular/core'
import { mergeMap, Observable, of, tap } from 'rxjs'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import {
	AlertRuleDTO,
	UpdateAlertRule
} from '../../shared/model/alert-rules.model'
import { BackendAlertRuleDTO } from '../../shared/model/backend-device-model'

export const alertRuleFeatureName = 'alertRule'

@StateRepository()
@State<CollatableEntityCollections<AlertRuleDTO>>({
	name: alertRuleFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class AlertRuleState extends CollatableEntityCollectionsRepository<
	AlertRuleDTO,
	EntityCollation
> {
	constructor(private genericEntityAPIService: GenericEntityAPIService) {
		super()
	}

	@Selector()
	public static alertRules(
		state: CollatableEntityCollections<AlertRuleDTO>
	): EntityDictionary<string, AlertRuleDTO> {
		return state.entities
	}

	private static toAlertRules(res: BackendAlertRuleDTO): AlertRuleDTO {
		return {
			id: res._id,
			name: res._name,
			heartRate: {
				maxCritical: res.maxCriticalHeartRate,
				minCritical: res.minCriticalHeartRate,
				maxNormal: res.maxNormalHeartRate,
				minNormal: res.minNormalHeartRate
			},
			bodyTemperature: {
				maxCritical: res.maxCriticalBodyTemperature,
				minCritical: res.minCriticalBodyTemperature,
				maxNormal: res.maxNormalBodyTemperature,
				minNormal: res.minNormalBodyTemperature
			},
			diastolicPressure: {
				maxCritical: res.maxCriticalDiastolicPressure,
				minCritical: res.minCriticalDiastolicPressure,
				maxNormal: res.maxNormalDiastolicPressure,
				minNormal: res.minNormalDiastolicPressure
			},
			respirationRate: {
				maxCritical: res.maxCriticalRespirationRate,
				minCritical: res.minCriticalRespirationRate,
				maxNormal: res.maxNormalRespirationRate,
				minNormal: res.minNormalRespirationRate
			},
			spO2: {
				maxCritical: res.maxCriticalSpO2,
				minCritical: res.minCriticalSpO2,
				maxNormal: res.maxNormalSpO2,
				minNormal: res.minNormalSpO2
			},
			systolicPressure: {
				maxCritical: res.maxCriticalSystolicPressure,
				maxNormal: res.maxNormalSystolicPressure,
				minCritical: res.minCriticalSystolicPressure,
				minNormal: res.minNormalSystolicPressure
			},
			bloodGlucose: {
				maxCritical: res.maxCriticalBloodGlucose,
				maxNormal: res.maxNormalBloodGlucose,
				minCritical: res.minCriticalBloodGlucose,
				minNormal: res.minNormalBloodGlucose
			},
			exitBed: {
				monitorFrom: res.exitBedRiskMonitorFrom,
				monitorTill: res.exitBedRiskMonitorTill
			}
		}
	}

	@DataAction()
	public addNewAlertRule(
		@Payload('alertRule') alertRule: GenericEntityResponse
	) {
		this.upsertOne(
			AlertRuleState.toAlertRules(alertRule as BackendAlertRuleDTO)
		)
	}

	@DataAction()
	public updateAlert(
		@Payload('entityId') id: string,
		@Payload('entityDiff') entityDiff: UpdateAlertRule
	): Observable<void> {
		return this.genericEntityAPIService
			.updateGenericEntity(id, entityDiff as UpdateGenericEntityRequest)
			.pipe(
				mergeMap((res) => {
					this.upsertOne(
						AlertRuleState.toAlertRules(res as BackendAlertRuleDTO)
					)
					return of()
				}),
				mapToVoid()
			)
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					_templateId: {
						// @ts-ignore
						in: ['c159c6bf-8aed-4965-b1cf-4b7015a19948']
					},
					...(ids
						? {
								_id: {
									// @ts-ignore
									in: ids
								}
						  }
						: null)
				}
			})
			.pipe(
				tap((res: SearchResponseGenericEntityResponse) =>
					this.upsertAllAlertRules(res)
				),
				mapToVoid()
			)
	}

	private upsertAllAlertRules(res: SearchResponseGenericEntityResponse) {
		const alertRules = res.data.map((e) =>
			AlertRuleState.toAlertRules(e as BackendAlertRuleDTO)
		)
		this.upsertMany(alertRules)
	}
}
