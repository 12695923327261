import { NgModule } from '@angular/core'
import { AgeFromBirthPipe } from './pipe/age-from-birth.pipe'
import { ShortNamePipe } from './pipe/short-name.pipe'
import { DateFnsModule } from 'ngx-date-fns'
import { PosturePipe } from './pipe/posture.pipe'
import { DeviceImagePipe } from './pipe/device-image.pipe'
import { RoundNumberPipe } from './pipe/round-number.pipe'
import { RoundFixNumberPipe } from './pipe/round-fix-number.pipe'
import { TimesDayPipe } from './pipe/times-day.pipe'
import { MedicalSignSeverityPipe } from './pipe/sign-severity/medical-sign-severity.pipe'
import { AiomedSpinnerDirective } from './directives/aiomed-spinner.directive'
import { BloodPressureSignSeverityPipe } from './pipe/sign-severity/blood-pressure-sign-severity.pipe'
import { AlertSubjectIconPipe } from './pipe/alert/alert-subject-icon.pipe'
import { AlertStatusIconPipe } from './pipe/alert/alert-status-icon.pipe'
import { AlertSeverityIconPipe } from './pipe/alert/alert-severity-icon.pipe'
import { AlertSubjectNamePipe } from './pipe/alert/alert-subject-name.pipe'
import { CheckTimeActiveMeasurementPipe } from './pipe/check-measurement/check-time-active-measurement.pipe'
import { AlertSubjectUnitPipe } from './pipe/alert/alert-subject-unit.pipe'
import { AlertStatusNamePipe } from './pipe/alert/alert-status-name.pipe'
import { AlertDeltaPipe } from './pipe/alert/alert-delta.pipe'

@NgModule({
	declarations: [
		AgeFromBirthPipe,
		ShortNamePipe,
		RoundNumberPipe,
		RoundFixNumberPipe,
		PosturePipe,
		TimesDayPipe,
		DeviceImagePipe,
		MedicalSignSeverityPipe,
		AiomedSpinnerDirective,
		BloodPressureSignSeverityPipe,
		AlertSubjectIconPipe,
		AlertStatusIconPipe,
		AlertSeverityIconPipe,
		AlertSubjectNamePipe,
		CheckTimeActiveMeasurementPipe,
		AlertSubjectUnitPipe,
		AlertStatusNamePipe,
		AlertDeltaPipe
	],
	imports: [DateFnsModule.forRoot()],
	exports: [
		AgeFromBirthPipe,
		ShortNamePipe,
		RoundNumberPipe,
		RoundFixNumberPipe,
		PosturePipe,
		TimesDayPipe,
		DeviceImagePipe,
		MedicalSignSeverityPipe,
		AiomedSpinnerDirective,
		BloodPressureSignSeverityPipe,
		AlertSubjectIconPipe,
		AlertStatusIconPipe,
		AlertSeverityIconPipe,
		AlertSubjectNamePipe,
		CheckTimeActiveMeasurementPipe,
		AlertSubjectUnitPipe,
		AlertStatusNamePipe,
		AlertDeltaPipe
	]
})
export class CoreModule {}
