import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { ErrorStateInterface } from './types/errorState.interface'

export const errorFeatureName = 'error'

@StateRepository()
@State<ErrorStateInterface>({
	name: errorFeatureName,
	defaults: {
		code: null,
		message: null,
		serviceName: null,
		traceId: null,
		environment: null,
		details: null
	}
})
@Injectable()
export class ErrorState extends NgxsDataRepository<ErrorStateInterface> {
	constructor() {
		super()
	}

	@Selector()
	static errorMessage(state: ErrorStateInterface): string | null {
		return state.message
	}

	@DataAction()
	public setError(@Payload('error') error: ErrorStateInterface) {
		this.ctx.patchState({ ...error })
	}

	@DataAction()
	public clear() {
		this.ctx.patchState({
			code: null,
			message: null,
			serviceName: null,
			traceId: null,
			environment: null,
			details: null
		})
	}
}
