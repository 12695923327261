<nz-radio-group [(ngModel)]='resolution' nzButtonStyle='solid'>
  <label nz-radio-button nzValue='false'>False</label>
  <label nz-radio-button nzValue='na'>NA</label>
  <label nz-radio-button nzValue='duplicate'>Done</label>
</nz-radio-group>
<form nz-form nzLayout='vertical'>
  <nz-form-item>
    <nz-form-label>Please enter employee ID</nz-form-label>
    <nz-form-control
      [nzValidateStatus]='alertValidateStatus'
      nzErrorTip='Incorrect Employee ID'>
      <input (change)='handlerAlertInputChange($event)' nz-input />
    </nz-form-control>
  </nz-form-item>
  <button (click)='handlerClickCancelButton()' class='btn-def' nz-button nzBlock nzType='primary'>Cancel
  </button>
  <button (click)='submitForm()' class='btn-prime' nz-button nzBlock nzType='primary' type='submit'>Dismiss Alert
  </button>
</form>
