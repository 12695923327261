import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { catchError, Observable, of, tap } from 'rxjs'
import {
	CaregiverAPIService,
	OrganizationUserResponse
} from 'biot-client-organization'
import { UserStateInterface } from './types/userState.interface'
import { FileState } from '../file/file.state'
import { GetCurrentUserResponse } from './types/getCurrentUserResponce'
import { UserDTO, UserInterface } from '../../shared/model/user.model'
import { AuthState } from '../auth/auth.state'
import { CaregiverResponse } from '../../../../biot-client-organization/src'
import { mapToVoid } from '@angular-ru/cdk/rxjs'

export const userFeatureName = 'user'

@StateRepository()
@State<UserStateInterface>({
	name: userFeatureName,
	defaults: {
		user: null,
		isLoading: false
	}
})
@Injectable()
export class UserState extends NgxsDataRepository<UserStateInterface> {
	constructor(
		private caregiverAPIService: CaregiverAPIService,
		private fileState: FileState,
		private authState: AuthState
	) {
		super()
	}

	@Selector([FileState.files])
	static currentUser(
		state: UserStateInterface,
		files: any
	): UserInterface | null {
		if (!state.user || !state.user.avatar || !Object.values(files).length) {
			return state.user
		}
		return {
			...state.user,
			signedUrl: files[state.user.avatar.id].signedUrl
		}
	}

	private static toUserDTO(res: GetCurrentUserResponse): UserDTO {
		return {
			...res,
			id: res._id,
			name: res._name,
			email: res._email,
			locale: res._locale,
			gender: res._gender,
			dateOfBirth: res._dateOfBirth,
			address: res._address,
			template: res._template,
			avatar: res.avatar,
			messagingToken: res.messagingToken
		}
	}

	public override ngxsOnInit() {
		this.authState.state$
			.pipe(
				tap((s) => {
					if (s.accessJwt != null) this.getCurrentUser()
					else {
						this.patchState({ user: null, isLoading: false })
					}
				})
			)
			.subscribe()
	}

	@DataAction({ subscribeRequired: false })
	public getCurrentUser(): Observable<OrganizationUserResponse | null> {
		this.ctx.patchState({
			isLoading: true
		})
		if (!this.authState.isAuthenticated()) {
			this.ctx.patchState({ user: null, isLoading: false })
			return of(null)
		}
		return this.caregiverAPIService.getCaregiverSelf().pipe(
			tap((res: CaregiverResponse) => {
				const user = UserState.toUserDTO(res)
				if (user.avatar) {
					this.fileState.loadEntities([user.avatar.id])
				}
				this.ctx.patchState({
					user,
					isLoading: false
				})
			}),
			catchError(() => {
				this.ctx.patchState({ user: null, isLoading: false })
				return of(null)
			})
		)
	}

	@DataAction()
	public updateUser(@Payload('data') data: UserDTO): Observable<void> {
		return (
			this.caregiverAPIService
				// @ts-ignore
				.updateCaregiver(this.getState().user.id, data)
				.pipe(
					tap((res: CaregiverResponse) => {
						const user = UserState.toUserDTO(res)
						if (user.avatar) {
							this.fileState.loadEntities([user.avatar.id])
						}
						this.ctx.patchState({
							user,
							isLoading: false
						})
					}),
					mapToVoid()
				)
		)
	}
}
