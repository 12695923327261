import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	AlertDTO,
	alertSeverityComparator,
	UpdateAlertInterface
} from '../../shared/model/alert'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import {
	GenericEntityAPIService,
	GenericEntityResponse,
	SearchResponseGenericEntityResponse,
	UpdateGenericEntityRequest
} from 'biot-client-generic-entity'
import { Injectable } from '@angular/core'
import {
	concatMap,
	EMPTY,
	forkJoin,
	mergeMap,
	Observable,
	of,
	switchMap,
	tap,
	timer
} from 'rxjs'
import { cloneDeep, isEqual, orderBy } from 'lodash-es'
import { entitiesFilter } from '../../core/helpers/filter'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import { UserState } from '../user/user.state'

export const alertFeatureName = 'alert'

// @ts-ignore
@StateRepository()
@State<CollatableEntityCollections<AlertDTO>>({
	name: alertFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class AlertState extends CollatableEntityCollectionsRepository<
	AlertDTO,
	EntityCollation
> {
	constructor(
		private genericEntityAPIService: GenericEntityAPIService,
		private userState: UserState
	) {
		super()
	}

	public get backendUpdates$(): Observable<number> {
		return timer(0, 10000).pipe(
			switchMap((n) =>
				forkJoin([this.loadPatientAlerts(), this.loadDeviceAlerts()]).pipe(
					concatMap((_) => of(n))
				)
			)
		)
	}

	@Selector()
	public static totalCount(
		state: CollatableEntityCollections<AlertDTO>
	): number {
		return state.totalCount
	}

	@Selector()
	public static devicesAlertsCount(
		state: CollatableEntityCollections<AlertDTO>
	): number {
		return Object.values(state.entities).filter(
			(v: AlertDTO) => v.alertedDevice && v.status === 'open'
		).length
	}

	@Selector()
	public static alertMuteCount(
		state: CollatableEntityCollections<AlertDTO>
	): number {
		return Object.values(state.entities).filter(
			(v: AlertDTO) => v.status === 'snoozed'
		).length
	}

	@Selector()
	public static alerts(
		state: CollatableEntityCollections<AlertDTO>
	): EntityDictionary<string, AlertDTO> {
		return state.entities
	}

	@Selector()
	public static alertsDTO(
		state: CollatableEntityCollections<AlertDTO>
	): AlertDTO[] {
		return entitiesFilter(
			state.freeTextFilter,
			Object.values(state.entities)
		).sort((a, b) => alertSeverityComparator(a.severity, b.severity))
	}

	@Selector()
	public static serverSideAlertsDTO(
		state: CollatableEntityCollections<AlertDTO>
	): AlertDTO[] {
		return state.allAlerts
			.map(AlertState.toAlertDTO)
			.sort((a, b) => alertSeverityComparator(a.severity, b.severity))
	}

	@Selector()
	public static alertsTotalTableCount(
		state: CollatableEntityCollections<AlertDTO>
	): number {
		return entitiesFilter(
			state.freeTextFilter,
			Object.values(state.entities)
		).sort((a, b) => alertSeverityComparator(a.severity, b.severity)).length
	}

	private static toAlertDTO(res: GenericEntityResponse): AlertDTO {
		return {
			...res,
			id: res._id,
			name: res._name,
			alertedDevice: (res as any).alertedDevice,
			patient: (res as any).patient,
			creationTime: new Date(res._creationTime),
			resolution: (res as any).resolution || '',
			resolvedBy: (res as any).resolvedBy || '',
			snoozedUntilTime: (res as any).snoozedUntilTime,
			severity: (res as any).severity,
			status: (res as any).status,
			subject: (res as any).subject?.[0],
			lastModifiedTime: new Date(res._lastModifiedTime)
		}
	}

	@DataAction()
	public loadDeviceAlerts() {
		// @ts-ignore
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					status: {
						in: ['open', 'snoozed']
					},
					_templateId: {
						// @ts-ignore
						in: ['05d9c5d3-d854-4894-9fac-d02f166e11f8']
					},
					severity: {
						eq: 'critical'
					},
					'alertedDevice.id': {
						// @ts-ignore
						isNotNull: true
					}
				}
			})
			.pipe(
				tap((res: SearchResponseGenericEntityResponse) => {
					const stateDeviceAlerts = orderBy(
						Object.values(this.ctx.getState().entities).filter(
							(v: AlertDTO) => v.alertedDevice
						),
						'name',
						'asc'
					)
					const deviceAlerts = orderBy(
						res.data.map(AlertState.toAlertDTO),
						'name',
						'asc'
					)
					if (deviceAlerts.length > stateDeviceAlerts.length) {
						this.upsertAllAlerts(res)
					} else if (deviceAlerts.length < stateDeviceAlerts.length) {
						stateDeviceAlerts.forEach((el) => {
							const idx = deviceAlerts.findIndex((a) => a.id === el.id)
							if (idx === -1) {
								this.removeOne(el.id)
							}
						})
					} else {
						const equal: boolean = isEqual(stateDeviceAlerts, deviceAlerts)
						if (equal) return
						this.upsertAllAlerts(res)
					}
				})
			)
	}

	@DataAction()
	public loadPatientAlerts() {
		// @ts-ignore
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					status: {
						in: ['open', 'snoozed']
					},
					_templateId: {
						// @ts-ignore
						in: ['05d9c5d3-d854-4894-9fac-d02f166e11f8']
					},
					severity: {
						// @ts-ignore
						eq: 'critical'
					}
				}
			})
			.pipe(
				tap((res: SearchResponseGenericEntityResponse) => {
					const stateAlerts = orderBy(
						Object.values(this.ctx.getState().entities),
						'name',
						'asc'
					)
					const alerts = orderBy(
						res.data.map(AlertState.toAlertDTO),
						'name',
						'asc'
					)
					if (alerts.length > stateAlerts.length) {
						this.upsertAllAlerts(res)
					} else if (alerts.length < stateAlerts.length) {
						stateAlerts.forEach((el) => {
							const idx = alerts.findIndex((a) => a.id === el.id)
							if (idx === -1) {
								this.removeOne(el.id)
							}
						})
					} else {
						const equal: boolean = isEqual(stateAlerts, alerts)
						if (equal) return
						this.upsertAllAlerts(res)
					}
				})
			)
	}

	@DataAction()
	public updateAlert(
		@Payload('entityId') id: string,
		@Payload('update') data: UpdateAlertInterface
	): Observable<void> {
		const user = this.userState.getState().user
		const entityDiff = data.resolution
			? {
					...data,
					resolvedBy: {
						id: user?.id,
						name: `${user?.name.lastName} ${user?.name.firstName}`,
						parentEntityId: null,
						templateId: (user as any)._template.id
					}
			  }
			: {
					...data,
					resolvedBy: null
			  }
		return this.genericEntityAPIService
			.updateGenericEntity(id, entityDiff as UpdateGenericEntityRequest)
			.pipe(
				mergeMap((alert) => {
					const allAlerts = cloneDeep(this.ctx.getState().allAlerts)
					const idx = allAlerts
						.map(AlertState.toAlertDTO)
						.findIndex((a) => a.id === AlertState.toAlertDTO(alert).id)
					allAlerts[idx] = alert
					this.ctx.patchState({
						allAlerts
					})
					this.upsertOne(AlertState.toAlertDTO(alert))
					return of()
				}),
				mapToVoid()
			)
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private upsertAllAlerts(res: SearchResponseGenericEntityResponse) {
		let alerts = res.data.map(AlertState.toAlertDTO)
		this.upsertMany(alerts)
	}
}
