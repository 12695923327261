/**
 * BioT Device Service
 * APIs document (version: 7.123.8)
 *
 * The version of the OpenAPI document: 7.123.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceOperationalStatusResponse { 
    _status?: DeviceOperationalStatusResponse.StatusEnum;
    _message?: string;
}
export namespace DeviceOperationalStatusResponse {
    export type StatusEnum = 'OK' | 'MINOR' | 'MAJOR' | 'CRITICAL';
    export const StatusEnum = {
        Ok: 'OK' as StatusEnum,
        Minor: 'MINOR' as StatusEnum,
        Major: 'MAJOR' as StatusEnum,
        Critical: 'CRITICAL' as StatusEnum
    };
}


