import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BuiltInTemplatesAPIService } from './api/built-in-templates-api.service';
import { CaregiverAPIService } from './api/caregiver-api.service';
import { HealthCheckAPIService } from './api/health-check-api.service';
import { InternalTemplateAPIService } from './api/internal-template-api.service';
import { InvitationAPIService } from './api/invitation-api.service';
import { OrganizationAPIService } from './api/organization-api.service';
import { OrganizationUserAPIService } from './api/organization-user-api.service';
import { OverviewAPIService } from './api/overview-api.service';
import { PatientAPIService } from './api/patient-api.service';
import { TemporaryTokenOperationAPIService } from './api/temporary-token-operation-api.service';
import { UserAPIService } from './api/user-api.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
