<section class='aiomed-sider'>
  <ul class='aiomed-sider-menu'>
    <li [routerLinkActiveOptions]='{exact:true}' queryParamsHandling='merge' routerLink='/' routerLinkActive='active'>
      <nz-badge [nzCount]='(patientsAlertsCount$ | async) || 0'>
        <svg height='42' viewBox='0 0 22 42' width='22' xmlns='http://www.w3.org/2000/svg'>
          <g data-name='Сгруппировать 1214' id='Сгруппировать_1214' transform='translate(-38 -72)'>
            <text font-family='Lato-Regular, Lato' font-size='8' id='Home' transform='translate(38 112)'>
              <tspan x='0' y='0'>{{'nav.home' | translate}}</tspan>
            </text>
            <path
              d='M10,28h5V19.667h7.333V28h5V15L18.667,8.5,10,15ZM8,30V14L18.667,6l10.667,8V30h-9V21.667H17V30ZM18.667,18.233Z'
              id='home_FILL0_wght400_GRAD0_opsz48'
              transform='translate(30 66)' />
          </g>
        </svg>
      </nz-badge>
    </li>
    <li [routerLinkActiveOptions]='{exact:true}' queryParamsHandling='merge' routerLink='/all-patients'
        routerLinkActive='active'>
      <nz-badge [nzCount]='(patientsAlertsCount$ | async) || 0'>
        <svg height='42.5' viewBox='0 0 29 42.5' width='29' xmlns='http://www.w3.org/2000/svg'>
          <g data-name='Сгруппировать 1213' id='Сгруппировать_1213' transform='translate(-35 -144)'>
            <g data-name='Сгруппировать 1209' id='Сгруппировать_1209' transform='translate(0 8.5)'>
              <text font-family='Lato-Regular, Lato' font-size='8' id='Patients' transform='translate(35 176)'>
                <tspan x='0' y='0'>{{'nav.allPatients' | translate}}</tspan>
              </text>
            </g>
            <path
              d='M5.8,28A1.793,1.793,0,0,1,4,26.2V5.8A1.793,1.793,0,0,1,5.8,4h12a1.793,1.793,0,0,1,1.8,1.8V26.2A1.793,1.793,0,0,1,17.8,28Zm0-13.35a4.294,4.294,0,0,1,1.365-.78A4.877,4.877,0,0,1,8.8,13.6h6a4.877,4.877,0,0,1,1.635.27,4.294,4.294,0,0,1,1.365.78V5.8H5.8Zm6-2.55A2.1,2.1,0,0,1,9.7,10a2.1,2.1,0,0,1,3.585-1.485,2.1,2.1,0,0,1,0,2.97A2.023,2.023,0,0,1,11.8,12.1Zm-6,14.1h12V18.4a2.971,2.971,0,0,0-3-3h-6a2.971,2.971,0,0,0-3,3Zm5.1-2.1h1.8V21.7h2.4V19.9H12.7V17.5H10.9v2.4H8.5v1.8h2.4ZM5.8,26.2h0Z'
              id='inpatient_FILL0_wght400_GRAD0_opsz48' transform='translate(38 140)' />
          </g>
        </svg>

      </nz-badge>
    </li>
    <li [routerLinkActiveOptions]='{exact:true}' queryParamsHandling='merge' routerLink='/devices'
        routerLinkActive='active'>
      <nz-badge [nzCount]='(devicesAlertsCount$ | async) || 0'>
        <svg height='41.334' viewBox='0 0 33.94 41.334' width='33.94' xmlns='http://www.w3.org/2000/svg'>
          <g data-name='Сгруппировать 1212' id='Сгруппировать_1212' transform='translate(-32 -216.666)'>
            <g data-name='Сгруппировать 1208' id='Сгруппировать_1208' transform='translate(1 24)'>
              <text font-family='Lato-Regular, Lato' font-size='8' id='Devices' transform='translate(34 232)'>
                <tspan x='0' y='0'>{{'nav.device' | translate}}</tspan>
              </text>
            </g>
            <path
              d='M9.178,32.833a17.874,17.874,0,0,1-3.627-5.4,16.279,16.279,0,0,1-1.343-6.6,16.507,16.507,0,0,1,1.322-6.6,17.157,17.157,0,0,1,3.648-5.4L10.7,10.356a15.028,15.028,0,0,0-3.179,4.7,14.361,14.361,0,0,0-1.165,5.776,14.2,14.2,0,0,0,1.165,5.753A15.668,15.668,0,0,0,10.7,31.31Zm5.06-5.06a10.62,10.62,0,0,1-2.083-3.112,9.34,9.34,0,0,1-.783-3.828,9.578,9.578,0,0,1,.761-3.828,9.893,9.893,0,0,1,2.105-3.112l1.522,1.522a7.775,7.775,0,0,0-1.633,2.418,7.428,7.428,0,0,0-.6,3,7.269,7.269,0,0,0,.6,2.978,8.427,8.427,0,0,0,1.633,2.44Zm6.94-4.3a2.675,2.675,0,1,1,1.858-.783A2.539,2.539,0,0,1,21.178,23.474Zm6.94,4.3L26.6,26.251a7.776,7.776,0,0,0,1.633-2.418,7.428,7.428,0,0,0,.6-3,7.269,7.269,0,0,0-.6-2.978,8.427,8.427,0,0,0-1.633-2.44l1.522-1.522a9.893,9.893,0,0,1,2.105,3.112,9.578,9.578,0,0,1,.761,3.828,9.4,9.4,0,0,1-.783,3.806A10.538,10.538,0,0,1,28.118,27.773Zm5.06,5.06L31.655,31.31a15.028,15.028,0,0,0,3.179-4.7A14.361,14.361,0,0,0,36,20.833a14.2,14.2,0,0,0-1.165-5.753,15.668,15.668,0,0,0-3.179-4.724l1.523-1.523a17.157,17.157,0,0,1,3.648,5.4,16.507,16.507,0,0,1,1.322,6.6,16.279,16.279,0,0,1-1.343,6.6A17.874,17.874,0,0,1,33.178,32.833Z'
              id='sensors_FILL0_wght300_GRAD-25_opsz40'
              transform='translate(27.792 207.833)' />
          </g>
        </svg>
      </nz-badge>
    </li>
    <li>
      <nz-badge [nzCount]='0'>
        <svg height='50' viewBox='0 0 35 50' width='35' xmlns='http://www.w3.org/2000/svg'>
          <g data-name='Сгруппировать 1219' id='Сгруппировать_1219' transform='translate(-31 -336)'>
            <g data-name='Сгруппировать 1215' id='Сгруппировать_1215'>
              <g data-name='Сгруппировать 1206' id='Сгруппировать_1206' transform='translate(0 32)'>
                <text font-family='Lato-Regular, Lato' font-size='8' id='Messages' transform='translate(31 352)'>
                  <tspan x='0' y='0'>{{'nav.messages' | translate}}</tspan>
                </text>
              </g>
              <path
                d='M8.8,18.43h9.39v-1.8H8.8Zm0-3.9H23.2v-1.8H8.8Zm0-3.9H23.2V8.83H8.8ZM4,28V5.8a1.744,1.744,0,0,1,.54-1.245A1.7,1.7,0,0,1,5.8,4H26.2a1.722,1.722,0,0,1,1.245.555A1.722,1.722,0,0,1,28,5.8V21.4a1.9,1.9,0,0,1-1.8,1.8H8.8Zm1.8-4.35L8.05,21.4H26.2V5.8H5.8ZM5.8,5.8v0Z'
                id='chat_FILL0_wght400_GRAD0_opsz48' transform='translate(32 340)' />
            </g>
          </g>
        </svg>
      </nz-badge>
    </li>
    <li [routerLinkActiveOptions]='{exact:true}' queryParamsHandling='merge' routerLink='/alerts'
        routerLinkActive='active'>
      <nz-badge [nzCount]='(patientsAlertsCount$ | async) || 0'>
        <svg height='47' viewBox='0 0 25 47' width='25' xmlns='http://www.w3.org/2000/svg'>
          <g data-name='Сгруппировать 1217' id='Сгруппировать_1217' transform='translate(-36 -467)'>
            <g data-name='Сгруппировать 1203' id='Сгруппировать_1203' transform='translate(2 16)'>
              <text font-family='Lato-Regular, Lato' font-size='8' id='Alerts' transform='translate(35 496)'>
                <tspan x='0' y='0'>{{'nav.alerts' | translate}}</tspan>
              </text>
            </g>
            <path
              d='M16,22a.974.974,0,1,0-.7-.285A.958.958,0,0,0,16,22Zm-.81-4.59h1.8V9.82h-1.8ZM16,28a11.611,11.611,0,0,1-4.65-.945,12.09,12.09,0,0,1-6.405-6.4,11.987,11.987,0,0,1,0-9.33,11.993,11.993,0,0,1,2.58-3.81A12.3,12.3,0,0,1,11.35,4.945a11.987,11.987,0,0,1,9.33,0,11.981,11.981,0,0,1,6.375,6.375,11.987,11.987,0,0,1,0,9.33,12.3,12.3,0,0,1-2.565,3.825,11.993,11.993,0,0,1-3.81,2.58A11.685,11.685,0,0,1,16,28Zm.03-1.8a9.78,9.78,0,0,0,7.2-2.985A9.89,9.89,0,0,0,26.2,15.97a9.8,9.8,0,0,0-2.97-7.2A9.84,9.84,0,0,0,16,5.8,9.855,9.855,0,0,0,8.785,8.77,9.815,9.815,0,0,0,5.8,16a9.829,9.829,0,0,0,2.985,7.215A9.865,9.865,0,0,0,16.03,26.2ZM16,16Z'
              id='error_FILL0_wght400_GRAD0_opsz48' transform='translate(32 468)' />
          </g>
        </svg>

      </nz-badge>
    </li>
  </ul>
  <div *ngIf='(user$ | async ) as user' class='menu-user-container'>
    <div [(nzPopoverVisible)]='popoverVisible' [nzPopoverContent]='contentTemplate'
         class='aiomed-sider-menu-user'
         nz-button
         nz-popover
         nzPopoverOverlayClassName='current-user-setting-popover'
         nzPopoverPlacement='bottomRight'
         nzPopoverTrigger='click'
         nzType='primary'
         style='cursor: pointer'>

      <span *ngIf='!user?.signedUrl'>{{ [user?.name?.firstName, user?.name?.lastName].join(' ') | shortName}}</span>
      <img *ngIf='user?.signedUrl' [src]='user?.signedUrl' />
      <div>{{user?.name?.firstName}} {{user?.name?.lastName}}</div>
      <ng-template #contentTemplate>
        <aiomed-user-popover (changeDepartment)='handlerChangeDepartment($event)'
                             (changeLanguageEmitter)='handlerChangeLanguageEmitter($event)'
                             [currentDepartment]='currentDepartment$ | async'
                             [currentUser]='user'
                             [departments]='departments$ | async'
                             [language]='language$ | async'></aiomed-user-popover>
      </ng-template>
    </div>
    <svg (click)='handlerClickLogout()' class='menu-logout' height='39.83' viewBox='0 0 29 39.83' width='33'
         xmlns='http://www.w3.org/2000/svg'>
      <g data-name='Сгруппировать 1293' id='Сгруппировать_1293' transform='translate(-34 -828.17)'>
        <g data-name='Сгруппировать 1204' id='Сгруппировать_1204' transform='translate(0 306)'>
          <text data-name='Log Out' font-family='Lato-Regular, Lato' font-size='8' id='Log_Out'
                transform='translate(34 560)'>
            <tspan x='0' y='0'>{{'nav.logOut' | translate}}</tspan>
          </text>
        </g>
        <path
          d='M7.819,27.829A1.865,1.865,0,0,1,6,26.01V7.819A1.865,1.865,0,0,1,7.819,6h8.823V7.819H7.819V26.01h8.823v1.819Zm14.735-5.609-1.3-1.3,3.093-3.093H13.731V16.005H24.282L21.19,12.913l1.3-1.3,5.336,5.336Z'
          id='logout_FILL0_wght400_GRAD0_opsz48'
          transform='translate(31 822.17)' />
      </g>
    </svg>
  </div>
</section>
