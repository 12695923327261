/**
 * BioT Organization Service
 * APIs document (version: 7.66.0)
 *
 * The version of the OpenAPI document: 7.66.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Name } from './name';


export interface FirstOrganizationAdminUserRequest { 
    _name: Name;
    /**
     * A description, can be very long
     */
    _description?: string;
    /**
     * An email address in the RFC-5322 specification
     */
    _email: string;
    /**
     * An E.164 formatted phone number
     */
    _phone?: string;
    /**
     * A java locale supported by the java runtime environment
     */
    _locale?: string;
    /**
     * A persons gender
     */
    _gender?: FirstOrganizationAdminUserRequest.GenderEnum;
    /**
     * A date without a time-zone in the ISO-8601 calendar system
     */
    _dateOfBirth?: string;
    _address?: Address;
    /**
     * Is the user enabled
     */
    _enabled?: boolean;
    _employeeId: string;
    /**
     * A password
     */
    password: string;
}
export namespace FirstOrganizationAdminUserRequest {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNDISCLOSED';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Undisclosed: 'UNDISCLOSED' as GenderEnum
    };
}


